const axios = require('axios');
import * as crypto from './crypto';
import * as helpers from './helpers';
import store from '../store';

let requests = {
  assetsNames: {
    crypto: [],
    currency: [],
    metals: [],
    funds: [],
  },
  gappers: [],
  prices: [],
  news: [],
};

/* eslint-disable */
const createCaller = async url => {
  const authentication = store.getters.getUserApiKey || "DemoAuthentication";
  const headersObject =
    url.includes('ipify.org') || url.includes('ipinfo.io')
      ? {}
      : {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + crypto.encrypt(process.env.VUE_APP_CLIENT, helpers.getAddonValue('Authori')),
        'Authentication': crypto.encrypt(authentication, helpers.getAddonValue('Authent')),
        'X-XSS-Protection': '1; mode=block',
        'X-Frame-Options': 'DENY'
      };
  return axios.create({
    baseURL: url,
    timeout: 30000,
    headers: headersObject
  });
};

const fillRequests = async (baseURL, source) => {
  if (baseURL.includes('assets') && !baseURL.includes('data')) {
    let assetType = baseURL.split('/')[baseURL.split('/').length - 1]
    requests.assetsNames[assetType].forEach(sourceRecord => {
      sourceRecord.cancel();
    });
    requests.assetsNames[assetType].push({ cancel: source.cancel });
  }
  if (baseURL.includes('tickers/gappers/batch')) {
    requests.gappers.forEach(sourceRecord => {
      sourceRecord.cancel();
    });
    requests.gappers.push({ cancel: source.cancel });
  }
  if (baseURL.includes('tickers/prices/batch')) {
    requests.prices.forEach(sourceRecord => {
      sourceRecord.cancel();
    });
    requests.prices.push({ cancel: source.cancel });
  }
  if (baseURL.includes('news/batch')) {
    requests.news.forEach(sourceRecord => {
      sourceRecord.cancel();
    });
    requests.news.push({ cancel: source.cancel });
  }
};

const clearRequests = async baseURL => {
  if (baseURL.includes('assets') && !baseURL.includes('data')) {
    let assetType = baseURL.split('/')[baseURL.split('/').length - 1]
    requests.assetsNames[assetType] = [];
  }
  if (baseURL.includes('tickers/gappers/batch')) {
    requests.gappers = [];
  }
  if (baseURL.includes('tickers/prices/batch')) {
    requests.prices = [];
  }
  if (baseURL.includes('news/batch')) {
    requests.news = [];
  }
};

const get = async (caller, parameters = {}) => {
  clearRequests(caller.defaults.baseURL);
  const source = axios.CancelToken.source();
  await fillRequests(caller.defaults.baseURL, source);
  return await caller
    .get('', { params: parameters, cancelToken: source.token })
    .then(res => {
      clearRequests(caller.defaults.baseURL);
      return res.data;
    })
    .catch(error => {
      clearRequests(caller.defaults.baseURL);
      if (axios.isCancel(error)) {}
      const message = error.response || error.message
      return message
    });
};

const post = async (caller, body) => {
  return await caller
    .post('', body)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      const message = error.response || error.message
      return message
    });
};

const patch = async (caller, body) => {
  return await caller
    .patch('', body)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      const message = error.response || error.message
      return message
    });
};

const DELETE = async (caller, body) => {
  return await caller
    .delete('', { data: body })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      const message = error.response || error.message
      return message
    });
};

const waitForSeconds = async seconds => {
  await new Promise(done => setTimeout(done, seconds * 1000));
};

export { createCaller, get, post, patch, DELETE, waitForSeconds };
