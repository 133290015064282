import * as paymentOperations from '../utils/operations/payments';

const store = {
  state: {
    productsObjects: null,
    pricesObjects: null,
    customerId: null,
    customerObject: null,
    customerInvoices: null,
    customerPaymentMethods: null,
    subscriptionId: null,
    subscriptionObject: null,
    subscriptionError: null,
    paymentMethodsIds: [],
    paymentIntentsIds: [],
    subscriptionsAmount: null,
  },
  mutations: {
    setProductsObjects(state, payload) {
      state.productsObjects = payload;
    },
    setPricesObjects(state, payload) {
      state.pricesObjects = payload;
    },
    setCustomerId(state, payload) {
      state.customerId = payload;
    },
    setCustomerObject(state, payload) {
      state.customerObject = payload;
    },
    setCustomerInvoices(state, payload) {
      state.customerInvoices = payload;
    },
    setCustomerPaymentMethods(state, payload) {
      state.customerPaymentMethods = payload;
    },
    setSubscriptionId(state, payload) {
      state.subscriptionId = payload;
    },
    setSubscriptionObject(state, payload) {
      state.subscriptionObject = payload;
    },
    setSubscriptionError(state, payload) {
      state.subscriptionError = payload;
    },
    setSubscriptionsAmount(state, payload) {
      state.subscriptionsAmount = payload;
    },
  },
  getters: {
    getProductsObjects: (state) => {
      return state.productsObjects;
    },
    getPricesObjects: (state) => {
      return state.pricesObjects;
    },
    getCustomerId: (state) => {
      return state.customerId;
    },
    getCustomerObject: (state) => {
      return state.customerObject;
    },
    getCustomerInvoices: (state) => {
      return state.customerInvoices;
    },
    getCustomerPaymentMethods: (state) => {
      return state.customerPaymentMethods;
    },
    getSubscriptionId: (state) => {
      return state.subscriptionId;
    },
    getSubscriptionObject: (state) => {
      return state.subscriptionObject;
    },
    getSubscriptionError: (state) => {
      return state.subscriptionError;
    },
    getSubscriptionsAmount: (state) => {
      return state.subscriptionsAmount;
    },
  },
  actions: {
    // Get product and plans info
    // Create subscription and first charge, if customer missing create and add payment method to account
    // Add necessary additional info in metadata key inside subscription object, CRUD actions needed
    // Update user record in database ans auth claims
    async getCustomerAction({ commit }) {
      await paymentOperations
        .retrieveCustomer(this.getters, `/customers`)
        .then((response) => {
          // result.customer.id is used to map back to the customer object
          // result.setupIntent.client_secret is used to create the payment method
          if (response.data.length > 0) {
            commit('setCustomerId', response.data[0].id);
            commit('setCustomerObject', response.data[0]);
            if (response.data.length > 1) {
              console.error(
                `User with email ${this.getters.getUserEmail} has multiple Stripe accounts!`
              );
            }
            return response.data[0];
          } else {
            return paymentOperations
              .createCustomer(this.getters, `/customers`)
              .then((response) => {
                commit('setCustomerId', response.id);
                commit('setCustomerObject', response.data);
                return response.data;
              })
              .catch((error) => {
                console.error(`\nError had occur: ${error.message}\n`);
                return null;
              });
          }
        })
        .catch((error) => {
          console.error(`\nError had occur: ${error.message}\n`);
          return null;
        });
    },

    async getInvoicesAction({ commit }) {
      await paymentOperations
        .listStripeData(this.getters, {
          dataType: `invoices`,
          customer: this.getters.getCustomerId,
        })
        .then((response) => {
          commit('setCustomerInvoices', response.data);
        })
        .catch((error) => {
          console.error(`\nError had occur: ${error.message}\n`);
        });
    },

    async getPaymentMethodsAction({ commit }) {
      await paymentOperations
        .listStripeData(this.getters, {
          dataType: `paymentMethods`,
          type: `card`,
          customer: this.getters.getCustomerId,
        })
        .then((response) => {
          commit('setCustomerPaymentMethods', response.data);
        })
        .catch((error) => {
          console.error(`\nError had occur: ${error.message}\n`);
        });
    },

    async getSubscriptionsAction({ commit }) {
      await paymentOperations
        .listStripeData(this.getters, {
          dataType: `subscriptions`,
        })
        .then((response) => {
          commit('setSubscriptionsAmount', response.data.length);
        })
        .catch((error) => {
          console.error(`\nError had occur: ${error.message}\n`);
        });
    },

    async getAssetsAction({ commit }) {
      await paymentOperations
        .getAsset(this.getters, {
          asset: `products`,
        })
        .then((response) => {
          commit('setProductsObjects', response.data);
        })
        .catch((error) => {
          console.error(`\nError had occur: ${error.message}\n`);
        });
      await paymentOperations
        .getAsset(this.getters, {
          asset: `prices`,
        })
        .then((response) => {
          commit('setPricesObjects', response.data);
        })
        .catch((error) => {
          console.error(`\nError had occur: ${error.message}\n`);
        });
    },

    async createSubscriptionAction({ commit, dispatch }, { stripe, payload }) {
      return (
        paymentOperations
          .createSubscription(this.getters, payload)
          .then((response) => {
            return response.data;
          })
          // If the card is declined, display an error to the user.
          .then((response) => {
            if (response.error) {
              // The card had an error when trying to attach it to a customer.
              throw response;
            }
            return response;
          })
          // Normalize the result to contain the object returned
          // by Stripe. Add the additional details we need.
          .then((response) => {
            return {
              ...{ stripe: stripe },
              ...payload,
              ...{ subscription: response },
            };
          })
          // Some payment methods require a customer to do additional
          // authentication with their financial institution.
          // Eg: 2FA for cards.
          .then(paymentOperations.handleCustomerActionRequired)
          // If attaching this card to a Customer object succeeds,
          // but attempts to charge the customer fail. You will
          // get a requires_payment_method error.
          .then(paymentOperations.handlePaymentMethodRequired)
          // No more actions required. Provision your service for the user.
          .then((response) => {
            commit('setSubscriptionId', response.subscription.id);
            commit('setSubscriptionObject', response.subscription);
          })
          .catch((error) => {
            if (error.errorLog) {
              error.error = error.errorLog.raw;
            }
            let settings = {
              timeout: 10000,
              color: 'error',
              text: error.error.message,
            };
            commit('setSubscriptionError', error.error);
            dispatch('showSnackbarAction', {
              status: true,
              settings: settings,
            });
            console.error(`\nError had occur: ${error.message}\n`);
          })
      );
    },

    async deleteSubscriptionAction({ commit, dispatch }, payload) {
      return paymentOperations
        .deleteSubscription(this.getters, {
          ...{ dataType: `subscriptions` },
          ...payload,
        })
        .then((response) => {
          commit('setSubscriptionId', response.data.id);
          commit('setSubscriptionObject', response.data);
          dispatch('getCustomerAction');
        })
        .catch((error) => {
          dispatch('showSnackbarAction', {
            status: true,
            settings: {
              timeout: 10000,
              color: 'error',
              text: 'Error canceling subscription!',
            },
          });
          console.error(`\nError had occur: ${error.message}\n`);
        });
    },
  },
};

export const paymentsStore = store;
