const endpoint = '/admins';
import * as http from '../http';

const getStatisticNews = async (getters, sinceDate) => {
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint
    : baseUrl + endpoint;
  const adminsCaller = await http.createCaller(
    fullUrl + '/statistic/news?date=' + sinceDate
  );
  return await http.get(adminsCaller, {});
};

const getStatisticPrices = async (getters, sinceDate, tickers) => {
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint
    : baseUrl + endpoint;
  const adminsCaller = await http.createCaller(
    fullUrl + '/statistic/prices?date=' + sinceDate + '&tickers=' + tickers
  );
  return await http.get(adminsCaller, {});
};

const getSettings = async (getters) => {
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint
    : baseUrl + endpoint;
  const adminsCaller = await http.createCaller(fullUrl + '/settings');
  return await http.get(adminsCaller, {});
};

const setSettings = async (getters, settings) => {
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint
    : baseUrl + endpoint;
  const adminsCaller = await http.createCaller(fullUrl + '/settings');
  return await http.patch(adminsCaller, settings);
};

const setUserClaims = async (getters, customClaims) => {
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint
    : baseUrl + endpoint;
  const payload = {
    email: customClaims.userEmail,
    action: 'createUserClaims',
    payload: customClaims,
  };
  const adminCaller = await http.createCaller(fullUrl);
  try {
    await http.post(adminCaller, payload);
  } catch (error) {
    console.error(`\nError had occur: ${error.message}\n`);
  }
};

export {
  getStatisticNews,
  getStatisticPrices,
  getSettings,
  setSettings,
  setUserClaims,
};
