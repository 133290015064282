import firebase from 'firebase/app';
import 'firebase/analytics';
import firebaseTestConfig from './config/config-test.json';
import firebaseProdConfig from './config/config-prod.json';

function getConfig() {
  let url = window.location.href.split('//')[1].split('/')[0];
  let currentEnvironment;
  switch (true) {
    case url.includes('localhost'):
      currentEnvironment = firebaseTestConfig;
      break;
    case url.includes('app.softalp') || url.includes('softalp-sls'):
      currentEnvironment = firebaseTestConfig;
      break;
    case !url.includes('localhost') &&
      !url.includes('app.softalp') &&
      !url.includes('softalp-sls') &&
      (url.includes('softalp.com') || url.includes('softalp-prod')):
      currentEnvironment = firebaseProdConfig;
      break;
    default:
      currentEnvironment = firebaseTestConfig;
      break;
  }
  return currentEnvironment;
}

firebase.initializeApp(getConfig());
firebase.analytics();
