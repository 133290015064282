import '@/firebase/';
import { capitalize } from './helpers';
import firebase from 'firebase/app';
import 'firebase/auth';

const getUserNameFromEmail = async (email) => {
  return capitalize(email.split('@')[0].replace('.', ' ').replace('_', ' '));
};

const updateUserProperties = async (userName) => {
  let properties = {};
  const user = firebase.auth().currentUser;
  if (!user.displayName) {
    properties.displayName = userName;
    user.updateProfile(properties);
  }
};

const sendEmailVerification = async () => {
  const signedInUser = firebase.auth().currentUser;
  await signedInUser
    .sendEmailVerification()
    .then(() => {})
    .catch((error) => {
      console.error(`\nError had occur: ${error.message}\n`);
    });
};

const sendPasswordResetEmail = async (email) => {
  await firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {})
    .catch((error) => {
      console.error(`\nError had occur: ${error.message}\n`);
    });
};

export {
  getUserNameFromEmail,
  updateUserProperties,
  sendEmailVerification,
  sendPasswordResetEmail,
};
