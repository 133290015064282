const endpoint = '/news';
import { createCaller, get } from '../http';

const getNews = async (getters, uri) => {
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint + uri
    : baseUrl + endpoint + uri;
  const newsCaller = await createCaller(fullUrl);
  return await get(newsCaller, {});
};

export { getNews };
