<template>
  <v-dialog v-model="confirmDialogShown" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Action confirmation</span>
        <v-spacer></v-spacer>
        <v-icon color="red" @click.stop="cancel">mdi-close-circle</v-icon>
      </v-card-title>
      <v-card-text>
        <div>
          {{ dialogSettings.message }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click.stop="confirm">{{
          strings.common.confirm
        }}</v-btn>
        <v-btn color="blue darken-1" text @click.stop="cancel">{{
          strings.common.cancel
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Confirm',
  props: {
    confirmDialogShown: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      dialogShown: this.confirmDialogShown,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.getStrings;
    },
    dialogSettings() {
      return this.$store.getters.getConfirmDialog || {};
    },
  },
  methods: {
    confirm() {
      const action = this.dialogSettings.action;
      const actionParameters = this.dialogSettings.actionParameters;
      action(actionParameters);
      this.dialogShown = false;
      this.$emit('update:confirmDialogShown', this.dialogShown);
    },
    cancel() {
      this.dialogShown = false;
      this.$emit('update:confirmDialogShown', this.dialogShown);
    },
  },
};
</script>

<style scoped></style>
