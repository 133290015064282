const endpoint = '/users';
import * as http from '../http';

const getUsers = async (getters) => {
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint
    : baseUrl + endpoint;
  const usersCaller = await http.createCaller(fullUrl);
  return await http.get(usersCaller, {});
};

const getUser = async (getters) => {
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint
    : baseUrl + endpoint;
  const usersCaller = await http.createCaller(
    fullUrl + '/' + getters.getUserEmail
  );
  return await http.get(usersCaller, {});
};

const createUser = async (getters) => {
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint
    : baseUrl + endpoint;
  const usersCaller = await http.createCaller(fullUrl);
  const userPayload = {
    userName: getters.getUserName,
    userEmail: getters.getUserEmail,
    userAccess: getters.getUserAccess,
    userApiCalls: getters.getUserApiCalls,
    userApiKey: getters.getUserApiKey,
    userMarket: getters.getUserMarket,
    userPaid: getters.isUserPaid,
    userPackage: getters.getUserPackage,
    userRole: getters.getUserRole,
    userSettings: getters.getUserSettings,
    userVerified: getters.isUserVerified,
    userPayments: [],
    userMessages: [],
    userMeta: {},
  };
  return await http.post(usersCaller, userPayload);
};

const updateUser = async (getters, payload) => {
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint
    : baseUrl + endpoint;
  const usersCaller = await http.createCaller(
    fullUrl + '/' + getters.getUserEmail
  );
  return await http.patch(usersCaller, payload);
};

const deleteUser = async (claims) => {};

const sendFeedback = async (getters, feedback) => {
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint
    : baseUrl + endpoint;
  const payload = {
    created: new Date(),
    to: 'admin',
    from: getters.getUserEmail,
    feedback: feedback,
  };
  const chatCaller = await http.createCaller(fullUrl + '/chat');
  return await http.post(chatCaller, payload);
};

const getFeedbacks = async (getters) => {
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint
    : baseUrl + endpoint;
  const chatCaller = await http.createCaller(fullUrl + '/chat');
  return await http.get(chatCaller);
};

export {
  getUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  sendFeedback,
  getFeedbacks,
};
