<template>
  <span>
    <v-navigation-drawer
      app
      v-model="drawer"
      disable-resize-watcher
      dark
      color="primary"
    >
      <v-list>
        <template>
          <div v-if="isAuthenticated">
            <div v-if="!isVerified">
              <v-btn class="red--text" text @click.stop="sendEmail"
                >VERIFY EMAIL</v-btn
              >
              <v-divider></v-divider>
            </div>
            <div v-else>
              <v-btn text to="/account" id="account">{{
                strings.header.account
              }}</v-btn>
              <v-divider></v-divider>
            </div>
            <v-divider></v-divider>
            <v-btn text to="/dashboard" id="dashboard">{{
              strings.header.dashboard
            }}</v-btn>
            <v-divider></v-divider>
            <v-btn text @click.stop="signout" id="signout">{{
              strings.header.sign_out
            }}</v-btn>
            <v-divider></v-divider>
          </div>
          <div v-else>
            <v-btn text to="/dashboard" id="demo" color="accent">Demo</v-btn>
            <v-divider></v-divider>
            <v-btn
              text
              @click.stop="signInDialogShown = true"
              id="signIn"
              :disabled="!signInActive"
              >{{ strings.header.sign_in }}</v-btn
            >
            <v-divider></v-divider>
            <v-btn
              text
              @click.stop="signUpDialogShown = true"
              id="signUp"
              :disabled="!signUpActive"
              >{{ strings.header.sign_up }}</v-btn
            >
            <v-divider></v-divider>
          </div>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      id="header"
      app
      elevate-on-scroll
      dark
      color="primary"
      class="white--text"
    >
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
        color="accent"
      ></v-app-bar-nav-icon>
      <v-spacer class="hidden-md-and-up"></v-spacer>
      <span class="hidden-md-and-up accent--text" v-if="trialDaysShown">
        {{ trialDaysMessage }}</span
      >
      <v-spacer class="hidden-md-and-up"></v-spacer>
      <v-toolbar-title>
        <v-container>
          <v-row class="mb-0 pb-0">
            <router-link to="/">
              <v-col>
                <v-row class="mt-1 pt-1 mtb-1 pb-1">
                  <v-img
                    max-width="70"
                    :src="require('@/assets/logo.png')"
                  ></v-img>
                </v-row>
              </v-col>
            </router-link>
            <router-link to="/dashboard">
              <v-col>
                <v-row
                  style="
                    letter-spacing: 0.2em;
                    border-bottom: 1px solid #dddddd;
                  "
                  class="ml-3 mt-3 pa-0 accent--text font-weight-bold"
                >
                  SOFTALP
                </v-row>
                <v-row
                  class="ml-3 pa-0 white--text text-subtitle-1 font-weight-light"
                >
                  INVESTOR DAILY 1-STOP
                </v-row>
              </v-col>
            </router-link>
          </v-row>
        </v-container>
        <h1 v-show="false">INVESTOR DAILY 1-STOP</h1>
      </v-toolbar-title>
      <v-spacer class="hidden-sm-and-down"></v-spacer>
      <span class="hidden-sm-and-down accent--text" v-if="trialDaysShown">
        {{ trialDaysMessage }}</span
      >
      <v-spacer class="hidden-sm-and-down"></v-spacer>
      <div class="hidden-sm-and-down">
        <div v-if="isAuthenticated">
          <span v-if="!isVerified">
            <v-btn color="accent" text @click.stop="sendEmail"
              >VERIFY EMAIL</v-btn
            >
          </span>
          <span v-else>
            <v-btn text to="/account" id="account">{{
              strings.header.account
            }}</v-btn>
          </span>
          <v-btn text to="/dashboard" id="dashboard">{{
            strings.header.dashboard
          }}</v-btn>
          <v-btn text @click.stop="signout" id="signout">{{
            strings.header.sign_out
          }}</v-btn>
        </div>
        <div v-else>
          <v-btn
            class="font-weight-black"
            text
            to="/dashboard"
            id="demo"
            color="accent"
            >Demo</v-btn
          >
          <v-btn
            text
            @click.stop="signInDialogShown = true"
            id="signIn"
            :disabled="!signInActive"
            >{{ strings.header.sign_in }}</v-btn
          >
          <v-btn
            text
            @click.stop="signUpDialogShown = true"
            id="signUp"
            :disabled="!signUpActive"
            >{{ strings.header.sign_up }}</v-btn
          >
        </div>
      </div>
      <SignIn :signInDialogShown.sync="signInDialogShown" />
      <SignUp :signUpDialogShown.sync="signUpDialogShown" />
    </v-app-bar>
  </span>
</template>

<script>
import SignIn from '@/components/modals/SignIn';
import SignUp from '@/components/modals/SignUp';

export default {
  name: 'Navigation',
  components: {
    SignIn,
    SignUp,
  },
  data() {
    return {
      appName: this.$store.getters.getAppName,
      drawer: false,
      signInDialogShown: false,
      signUpDialogShown: false,
      trialDaysShown: false,
      signInActive: false,
      signUpActive: false,
    };
  },
  computed: {
    siteSettings() {
      return this.$store.getters.getSiteSettings;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isVerified() {
      return this.$store.getters.isUserVerified;
    },
    isUserPaid() {
      return this.$store.getters.isUserPaid;
    },
    strings() {
      return this.$store.getters.getStrings;
    },
    trialDays() {
      return this.$store.getters.getUserTrialDays;
    },
    trialDaysMessage() {
      const currentTrialDays = this.$store.getters.getUserTrialDays;
      let message = null;
      let messageDays;
      let messageHours;

      switch (true) {
        case currentTrialDays.days > 1:
          messageDays = ` ${currentTrialDays.days} days`;
          break;
        case currentTrialDays.days == 1:
          messageDays = ` ${currentTrialDays.days} day`;
          break;
        case currentTrialDays.days == 0:
          if (currentTrialDays.hours == 0) {
            message = `Trial is over!`;
          }
          messageDays = '';
          break;
        default:
          messageDays = '';
          break;
      }

      switch (true) {
        case currentTrialDays.hours > 1:
          messageHours = ` ${currentTrialDays.hours} hours`;
          break;
        case currentTrialDays.hours == 1:
          messageHours = ` ${currentTrialDays.hours} hour`;
          break;
        case currentTrialDays.hours == 0:
          messageHours = '';
          break;
        default:
          messageHours = '';
          break;
      }

      return message ? message : `Trial ends in${messageDays}${messageHours}!`;
    },
    userReady() {
      return this.$store.getters.isUserReady;
    },
    subscriptionsAmount() {
      return this.$store.getters.getSubscriptionsAmount;
    },
  },
  methods: {
    signout() {
      this.$store.dispatch('userSignOut');
    },
    sendEmail() {
      this.$store.dispatch('sendEmail');
    },
    setTrialDaysShown() {
      this.trialDaysShown =
        !this.$store.getters.isUserPaid &&
        this.$store.getters.isAuthenticated &&
        this.$store.getters.isUserVerified;
    },
  },
  mounted() {},
  watch: {
    trialDays() {
      this.setTrialDaysShown();
    },
    siteSettings() {
      this.signInActive = this.$store.getters.getSiteSettings.signIn;
      this.signUpActive =
        this.$store.getters.getSiteSettings.signUp &&
        this.subscriptionsAmount < 100;
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
