import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/';
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta';
import VueAnalytics from 'vue-ua';
import x5GMaps from 'x5-gmaps';
import '@/firebase/';
import '@babel/polyfill';

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VueAnalytics, {
  // [Required] The name of your app as specified in Google Analytics.
  appName: 'Softalp',
  // [Required] The version of your app.
  appVersion: '1.5',
  // [Required] Your Google Analytics tracking ID.
  trackingId: 'UA-176794529-1',
  // If you're using vue-router, pass the router instance here.
  vueRouter: router,
});
Vue.use(x5GMaps, process.env.VUE_APP_MAPS_KEY);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
