import Vue from 'vue';
import Vuex from 'vuex';
import { adminsStore } from './admins';
import { generalStore } from './general';
import { tickersStore } from './tickers';
import { assetsStore } from './assets';
import { cryptoStore } from './crypto';
import { usersStore } from './users';
import { newsStore } from './news';
import { paymentsStore } from './payments';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [],
  state: {
    ...adminsStore.state,
    ...generalStore.state,
    ...tickersStore.state,
    ...assetsStore.state,
    ...cryptoStore.state,
    ...usersStore.state,
    ...newsStore.state,
    ...paymentsStore.state,
  },
  mutations: {
    ...adminsStore.mutations,
    ...generalStore.mutations,
    ...tickersStore.mutations,
    ...assetsStore.mutations,
    ...cryptoStore.mutations,
    ...usersStore.mutations,
    ...newsStore.mutations,
    ...paymentsStore.mutations,
  },
  getters: {
    ...adminsStore.getters,
    ...generalStore.getters,
    ...tickersStore.getters,
    ...assetsStore.getters,
    ...cryptoStore.getters,
    ...usersStore.getters,
    ...newsStore.getters,
    ...paymentsStore.getters,
  },
  actions: {
    ...adminsStore.actions,
    ...generalStore.actions,
    ...tickersStore.actions,
    ...assetsStore.actions,
    ...cryptoStore.actions,
    ...usersStore.actions,
    ...newsStore.actions,
    ...paymentsStore.actions,
  },
});
