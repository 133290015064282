<template>
  <v-app>
    <navigation></navigation>
    <v-main transition="slide-x-transition">
      <router-view></router-view>
    </v-main>
    <v-footer
      id="footer"
      absolute
      padless
      max-height="40"
      class="white--text opacity pa-1"
      color="gray darken-4"
      dark
    >
      <a
        style="text-decoration: none; color: inherit"
        @click.stop="cookiesDialogShown = true"
        class="ma-1"
        >Cookies</a
      >
      <a
        style="text-decoration: none; color: inherit"
        @click.stop="privacyDialogShown = true"
        class="ma-1"
        >Privacy</a
      >
      <a
        style="text-decoration: none; color: inherit"
        @click.stop="termsDialogShown = true"
        class="ma-1"
        >Terms</a
      >
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <a
            v-on="on"
            style="text-decoration: none; color: inherit"
            :href="emailString"
            class="ma-1"
            >Contact</a
          >
        </template>
        <span
          >Softalp Inc.<br />
          3790 El Camino Real<br />
          Palo Alto, CA, 94306<br />
          Phone: +1-650-2906-777<br />
          Email: order@softalp.com</span
        >
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <a
            v-on="on"
            style="text-decoration: none; color: inherit"
            :href="emailString"
            class="ma-1"
            >Invest</a
          >
        </template>
        <span
          >Participate in seed investment<br />
          round for Softalp Inc. (Delaware C)<br />
          Current terms are 250K for 10%<br />
          Phone: +1-650-2906-777<br />
          Email: order@softalp.com</span
        >
      </v-tooltip>
      <div class="text-center">
        <v-menu offset-y v-if="false">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" disabled>
              <v-img
                to="/"
                class="fill-height mr-2"
                contain
                position="left"
                max-width="20"
                :src="
                  userFlag.includes('us') && locale.includes('en')
                    ? require(`@/assets/flags/us.png`)
                    : require(`@/assets/flags/${locale}.png`)
                "
              ></v-img>
              Language {{ locale.toUpperCase() }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in translatedLocales"
              :key="index"
              @click="setLocale(item)"
            >
              <v-img
                to="/"
                class="fill-height mr-2"
                contain
                position="left"
                max-width="30"
                :src="
                  userFlag.includes('us') && item.includes('en')
                    ? require(`@/assets/flags/us.png`)
                    : require(`@/assets/flags/${item}.png`)
                "
              ></v-img>
              <v-list-item-title>{{ item.toUpperCase() }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-spacer></v-spacer>
      <div @click.stop="showDeployTime">
        <v-icon size="30" color="accent">mdi-alpha</v-icon>
        <span class="accent--text"
          >Alpha Quaeritis {{ appVersion.slice(0, -2) }}</span
        >
      </div>
      <v-spacer></v-spacer>
      <div class="ma-1">
        <v-btn
          id="admin"
          depressed
          max-width="30"
          v-if="userAccess"
          to="/admin"
          name="admin"
        ></v-btn>
        {{ companyName }} &copy;
        {{ new Date().getFullYear() }}
      </div>
    </v-footer>
    <vue-cookie-accept-decline
      :ref="'cookiePanel'"
      :elementId="'cookiePanel'"
      :debug="false"
      :position="'bottom-left'"
      :type="'floating'"
      :disableDecline="false"
      :transitionName="'slideFromBottom'"
      :showPostponeButton="false"
      @status="cookieStatus"
      @clicked-accept="cookieClickedAccept"
      @clicked-decline="cookieClickedDecline"
      @removedCookie="cookieRemovedCookie"
    >
      <div slot="message">
        {{ strings.modals.cookies.popup.text }}
        <a @click.stop="cookiesDialogShown = true">
          {{ strings.modals.cookies.popup.link }}
        </a>
      </div>
      <div slot="declineContent">
        {{ strings.modals.cookies.popup.disagree_button }}
      </div>
      <div slot="acceptContent">
        {{ strings.modals.cookies.popup.agree_button }}
      </div>
    </vue-cookie-accept-decline>
    <Cookies :cookiesDialogShown.sync="cookiesDialogShown" />
    <Privacy :privacyDialogShown.sync="privacyDialogShown" />
    <Terms :termsDialogShown.sync="termsDialogShown" />
    <Email :emailDialogShown.sync="emailDialogShown" />
    <Confirm :confirmDialogShown.sync="confirmDialogShown" />
    <v-snackbar
      v-model="snackbarShown"
      :timeout="snackbarSettings.timeout"
      :color="snackbarSettings.color"
      :top="snackbarSettings.top"
      :right="snackbarSettings.right"
    >
      <v-row align="center" justify="center">
        <span class="ml-2">{{ snackbarSettings.text }}</span>
        <v-spacer></v-spacer>
        <v-btn dark text @click="hideSnackbar"> Close </v-btn>
      </v-row>
    </v-snackbar>
  </v-app>
</template>

<script>
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import Navigation from '@/components/general/Navigation';
import Cookies from '@/components/modals/Cookies';
import Privacy from '@/components/modals/Privacy';
import Terms from '@/components/modals/Terms';
import Email from '@/components/modals/Email';
import Confirm from '@/components/modals/Confirm';

export default {
  name: 'App',
  metaInfo: {
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        name: 'description',
        content:
          'Softalp.com - STOCK MARKET GLOBAL MOVERS - Picked by Screening Algorithm, Gathered News by Custom Engine, Analysed Sentiment by AI & NLP, Eliminated Human Factor Forecast!',
      },
      {
        name: 'keywords',
        content:
          'America stock market, Europe stock market, German stock market, England stock market, India stock market,  China stock market,  Japan stock market, stock market forecast, stock market prediction, stock market movement,  stock market news analysis, stock movement analysis, day trading watchlist, top stocks, daily gappers, daily market overview, top stock market performers',
      },
      { property: 'og:title', content: 'Softalp - Investor Daily 1-stop' },
      { property: 'og:site_name', content: 'Softalp.com' },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:url',
        content: 'https://softalp.com',
      },
      {
        property: 'og:image',
        content: 'https://softalp.com/favicon.ico',
      },
      {
        property: 'og:description',
        content:
          'Softalp.com - STOCK MARKET GLOBAL MOVERS - Picked by Screening Algorithm, Gathered News by Custom Engine, Analysed Sentiment by AI & NLP, Eliminated Human Factor Forecast!',
      },
      { name: 'twitter:card', content: 'summary' },
      {
        name: 'twitter:site',
        content: 'https://softalp.com',
      },
      { name: 'twitter:title', content: 'Softalp - Investor Daily 1-stop' },
      {
        name: 'twitter:description',
        content:
          'Softalp.com - STOCK MARKET GLOBAL MOVERS - Picked by Screening Algorithm, Gathered News by Custom Engine, Analysed Sentiment by AI & NLP, Eliminated Human Factor Forecast!',
      },
      { itemprop: 'name', content: 'Softalp - Investor Daily 1-stop' },
      {
        itemprop: 'description',
        content:
          'Softalp.com - STOCK MARKET GLOBAL MOVERS - Picked by Screening Algorithm, Gathered News by Custom Engine, Analysed Sentiment by AI & NLP, Eliminated Human Factor Forecast!',
      },
      {
        itemprop: 'image',
        content: 'https://softalp.com/favicon.ico',
      },
    ],
    htmlAttrs: {
      lang: 'en',
      amp: true,
    },
  },
  components: {
    VueCookieAcceptDecline,
    Navigation,
    Cookies,
    Privacy,
    Terms,
    Email,
    Confirm,
  },
  data() {
    return {
      appName: this.$store.getters.getAppName,
      appVersion: process.env.VUE_APP_VERSION.split('-')[0],
      appCodeLastCommit: process.env.VUE_APP_LAST_COMMIT,
      companyName: this.$store.getters.getCompanyName,
      userFlag: null,
      translatedLocales: this.$store.getters.getBrowserLocalesTranslated,
      termsDialogShown: false,
      privacyDialogShown: false,
      cookiesDialogShown: false,
      emailDialogShown: false,
      confirmDialogShown: false,
      status: null,
      snackbarShown: false,
      snackbarSettings: {
        text: 'Default Text',
        timeout: 5000,
      },
    };
  },
  computed: {
    getCurrentEnvironment() {
      return this.$store.getters.getEnvironment;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    userName() {
      return this.$store.getters.getUserName;
    },
    userEmail() {
      return this.$store.getters.getUserEmail;
    },
    emailString() {
      return `mailto:order@softalp.com
        ?subject=Contact from ${this.userName}
        &body=Hi ...`;
    },
    confirmDialog() {
      return this.$store.getters.getConfirmDialog;
    },
    nextDialog() {
      return this.$store.getters.getNextDialog;
    },
    locale() {
      return this.$store.getters.getBrowserLocale;
    },
    userAccess() {
      return (
        this.$store.getters.getUserAccess >= 5 &&
        !this.$store.getters.getUserRole.includes('Trader')
      );
    },
    strings() {
      return this.$store.getters.getStrings;
    },
    statusText() {
      return this.status || 'No cookie set';
    },
    isSnackbarShown() {
      return this.$store.getters.isSnackbarShown;
    },
  },
  methods: {
    cookieStatus(status) {
      this.status = status;
    },
    cookieClickedAccept() {
      this.status = 'accept';
    },
    cookieClickedDecline() {
      this.status = 'decline';
    },
    cookieRemovedCookie() {
      this.status = null;
      this.$refs.cookiePanel.init();
    },
    removeCookie() {
      this.$refs.cookiePanel.removeCookie();
    },
    setLocale(locale) {
      const userLocaleCurrent = this.$store.getters.getBrowserLocale;
      if (locale != userLocaleCurrent) {
        this.$store.dispatch('browserLocale', locale);
      }
    },
    setStrings() {
      const userLocaleInitial = this.getLocale();
      this.userFlag = userLocaleInitial.includes('en-US')
        ? (this.userFlag = 'us')
        : (this.userFlag = this.locale);
      this.$store.dispatch('strings');
    },
    getLocale() {
      return navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.userLanguage ||
            navigator.locale ||
            navigator.browserLanguage ||
            'en';
    },
    setBaseUrl() {
      this.$store.dispatch('setBaseUrl');
    },
    setEnvironment() {
      this.$store.dispatch('setEnvironment');
    },
    getSiteSettings() {
      this.$store.dispatch('getSiteSettingsRecords');
    },
    getSubscriptions() {
      this.$store.dispatch('getSubscriptionsAction');
    },
    showDeployTime() {
      console.log(`\n Environment set to - ${this.getCurrentEnvironment} !\n`);
      console.log(`\n Environment node set to - ${process.env.NODE_ENV} !\n`);
      console.log(
        `\nApp version and last deployment time: ${process.env.VUE_APP_VERSION}\n`
      );
      console.log(
        '\n\nApp code last commit date/branch/message ' +
          process.env.VUE_APP_LAST_COMMIT +
          '\n\n'
      );
    },
    showSnackbar() {
      this.snackbarShown = this.$store.getters.isSnackbarShown;
      this.snackbarSettings = this.$store.getters.getSnackbarSettings;
    },
    hideSnackbar() {
      this.$store.dispatch('showSnackbarAction', {
        status: false,
        settings: {},
      });
    },
    showConfirmDialog() {
      this.confirmDialogShown = true;
    },
  },
  created() {
    this.setStrings();
    this.setBaseUrl();
    this.setEnvironment();
  },
  mounted() {
    this.getSiteSettings();
    this.getSubscriptions();
  },
  watch: {
    isSnackbarShown() {
      this.showSnackbar();
    },
    locale() {
      this.setStrings();
    },
    confirmDialog() {
      this.showConfirmDialog();
    },
    nextDialog() {
      const nextDialogName = this.$store.getters.getNextDialog;
      if (nextDialogName && nextDialogName.includes('privacy')) {
        this.privacyDialogShown = true;
      } else if (nextDialogName && nextDialogName.includes('terms')) {
        this.termsDialogShown = true;
      } else if (nextDialogName && nextDialogName.includes('email')) {
        this.emailDialogShown = true;
      }
      this.$store.dispatch('nextDialog', null);
    },
    $route(to, from) {
      document.title =
        to.meta && to.meta.title ? to.meta.title : this.strings.header.title;
    },
  },
};
</script>

<style>
.opacity {
  opacity: 0.85;
}
</style>
