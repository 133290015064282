<template>
  <v-container fluid fill-height class="home-background">
    <v-col justify-center>
      <v-row>
        <slogan></slogan>
      </v-row>
      <v-row>
        <price></price>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import Slogan from '@/components/home/Slogan';
import Price from '@/components/home/Price';

// Add https://www.digitalocean.com/community/tutorials/vuejs-google-analytics

export default {
  name: 'home',
  components: {
    Slogan,
    Price,
  },
};
</script>

<style scoped>
.home-background {
  background: linear-gradient(var(--v-primary-base), var(--v-primary-lighten5));
}
</style>
