<template>
  <v-container class="slogan">
    <v-layout justify-center align-center column pa-5>
      <div id="slogan_top" v-html="strings.home.slogan.first"></div>
      <div id="slogan_bottom" v-html="strings.home.slogan.second"></div>
      <v-row v-if="!isAuthenticated" class="mt-4 mb-5">
        <v-col cols="12">
          <v-row align="center" justify="center">
            <v-btn
              x-large
              depressed
              class="text-h6"
              color="accent"
              @click.stop="signUpDialogShown = true"
              :disabled="!signUpActive"
              >START 7-DAY FREE TRIAL</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row align="center" justify="center" class="outlined">
            <v-card
              to="/dashboard"
              width="300"
              height="471"
              :img="require('@/assets/gauge.png')"
              class="ma-3 pa-6"
              elevation="4"
              tile
            >
            </v-card>
            <v-card
              to="/dashboard"
              width="300"
              height="471"
              :img="require('@/assets/graph.png')"
              class="ma-3 pa-6"
              elevation="4"
              tile
            >
            </v-card>
          </v-row>
        </v-col>
      </v-row>
      <div id="slogan_bottom" v-html="strings.home.slogan.third"></div>
    </v-layout>
    <SignUp :signUpDialogShown.sync="signUpDialogShown" />
  </v-container>
</template>

<script>
import SignUp from '@/components/modals/SignUp';

export default {
  name: 'Slogan',
  components: {
    SignUp,
  },
  data() {
    return {
      signUpDialogShown: false,
      signUpActive: false,
    };
  },
  computed: {
    siteSettings() {
      return this.$store.getters.getSiteSettings;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isVerified() {
      return this.$store.getters.isUserVerified;
    },
    strings() {
      return this.$store.getters.getStrings;
    },
  },
  watch: {
    siteSettings() {
      this.setSiteSettings();
    },
  },
  methods: {
    setSiteSettings() {
      this.signUpActive = this.siteSettings.signUp;
    },
  },
  mounted() {
    this.siteSettings && this.setSiteSettings();
  },
};
</script>

<style scoped></style>
