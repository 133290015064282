import { getNews } from '../utils/operations/news';

const store = {
  state: {
    news: null,
  },
  mutations: {
    setNews(state, payload) {
      state.news = payload;
    },
  },
  getters: {
    getNews: (state) => {
      return state.news;
    },
  },
  actions: {
    async fetchTickerNews({ commit }, ticker) {
      commit('setLoader', true);
      const newsDate = this.getters.getDate;
      const newsMarket = this.getters.getUserMarket;
      const uri = `/batch?date=${newsDate}&market=${newsMarket}&tickers=${ticker}`;
      return await getNews(this.getters, uri).then((response) => {
        commit('setLoader', false);
        return response;
      });
    },

    async fetchNews({ commit }) {
      try {
        const newsDate = this.getters.getDate;
        const newsMarket = this.getters.getUserMarket;
        const tickersObjects = this.getters.getGappers;
        let tickersWithNewsArray = this.getters.getNewsGappers;
        let tickersForUri = '';
        tickersObjects.map((object) => {
          tickersForUri += object.ticker + ',';
        });
        tickersForUri = tickersForUri.slice(0, -1);
        const uri = `/batch?date=${newsDate}&market=${newsMarket}&tickers=${tickersForUri}`;
        let newsResponse = await getNews(this.getters, uri);

        // Correct tickers with news records
        newsResponse = newsResponse.filter((newsObject) => {
          return (
            newsObject.results != 0 &&
            tickersWithNewsArray.includes(newsObject.tickerName)
          );
        });

        tickersWithNewsArray = [];
        tickersWithNewsArray = newsResponse.map((newsObject) => {
          return newsObject.tickerName;
        });

        commit('setNewsGappers', tickersWithNewsArray);
        commit('setNews', newsResponse);
      } catch (error) {
        commit('setNews', ['none']);
        commit('setLoader', false);
        console.error(`\nError had occur: ${error.message}\n`);
      }
    },
  },
};

export const newsStore = store;
