import * as tickersOperations from '../utils/operations/tickers';

const store = {
  state: {
    date: null, // Operational date, can be demo -1,2,3 days
    gappers: null,
    gappersWeek: null,
    gappersNews: null,
    prices: null,
    pricesMapped: null,
    markets: ['america', 'england', 'europe', 'india', 'china', 'japan'],
  },
  mutations: {
    setDate(state, payload) {
      state.date = payload;
    },
    setGappers(state, payload) {
      state.gappers = payload;
    },
    setWeekGappers(state, payload) {
      state.gappersWeek = payload;
    },
    setNewsGappers(state, payload) {
      state.gappersNews = payload;
    },
    setPrices(state, payload) {
      state.prices = payload;
    },
    setPricesMapped(state, payload) {
      state.pricesMapped = payload;
    },
  },
  // https://docs.vuestorefront.io/guide/vuex/vuex-conventions.html#getters
  getters: {
    getDate: (state) => {
      return state.date;
    },
    getGappers: (state) => {
      return state.gappers;
    },
    getWeekGappers: (state) => {
      return state.gappersWeek;
    },
    getNewsGappers: (state) => {
      return state.gappersNews;
    },
    getPrices: (state) => {
      return state.prices;
    },
    getPricesMapped: (state) => {
      return state.pricesMapped;
    },
    getMarkets: (state) => {
      return state.markets;
    },
  },
  // https://docs.vuestorefront.io/guide/vuex/vuex-conventions.html#actions
  actions: {
    async updatePrices({ commit }) {},
    async switchGappers({ commit }, { date }) {
      commit('setNews', null);
      commit('setPrices', null);
      commit('setPricesMapped', null);
      commit('setNewsGappers', null);

      const dateGappers = this.getters.getWeekGappers.filter((record) => {
        return record.id == date;
      });

      commit('setDate', date.split('T')[0]);
      commit('setGappers', dateGappers[0].gappersTickersObjects);
      if (dateGappers[0].gappersTickersWithNews) {
        const newsGappers = dateGappers[0].gappersTickersWithNews;
        commit('setNewsGappers', newsGappers);
      }
    },
    async fetchGappers({ commit }) {
      try {
        // Reset gappers record on market switch etc.
        commit('setNews', null);
        commit('setPrices', null);
        commit('setPricesMapped', null);
        commit('setGappers', null);
        // Convert date to market date/time
        // TODO. Market is not changeable now, refactor.
        const market = this.getters.getUserMarket;
        const marketDateTimeString = await this.dispatch(
          'getMarketTime',
          market
        );

        let date = new Date(marketDateTimeString);

        const weekday = date.getDay();
        let minusDays, minusDemoDays;
        switch (weekday) {
          case 6:
            minusDays = 1;
            minusDemoDays = 1;
            break;
          case 0:
            minusDays = 2;
            minusDemoDays = 2;
            break;
          case 1:
            minusDays = 0;
            minusDemoDays = 3;
            break;
          default:
            minusDays = 0;
            minusDemoDays = 1;
            break;
        }

        const demoDate =
          !this.getters.isAuthenticated ||
          !this.getters.isUserVerified ||
          !this.getters.isUserReady
            ? date.setDate(date.getDate() - minusDemoDays)
            : date.setDate(date.getDate() - minusDays);

        let queryDate = new Date(demoDate);
        let yyyy = queryDate.getFullYear();
        let mm = queryDate.getMonth() + 1;
        let dd = queryDate.getDate();
        if (mm < 10) {
          mm = `0${mm}`;
        }
        if (dd < 10) {
          dd = `0${dd}`;
        }
        let isoDate = yyyy + '-' + mm + '-' + dd;

        commit('setDate', isoDate);
        commit('setUserMarket', market);
        const userMarket = this.getters.getUserMarket;
        let uri = `/gappers/batch?date=${isoDate}&market=${userMarket}`;
        commit('setLoader', true);

        // If existing weekGappers are not containing todays and yesterdays gappers - make a call - otherwise use existing
        const gapperResponse = await tickersOperations.getGappers(
          this.getters,
          uri
        );

        if (!gapperResponse)
          throw new Error(
            `No tickers found for requested date of '${isoDate}'!`
          );

        const weekGappers = gapperResponse.data;

        const isoDateGappers = gapperResponse.data.filter((gapperRecord) => {
          return gapperRecord.id.includes(isoDate);
        });
        if (!isoDateGappers[isoDateGappers.length - 1])
          throw new Error(
            `No tickers found for requested date of '${isoDate}'!`
          );
        const todaysGappers =
          isoDateGappers[isoDateGappers.length - 1].gappersTickersObjects;

        if (isoDateGappers[isoDateGappers.length - 1].gappersTickersWithNews) {
          const newsGappers =
            isoDateGappers[isoDateGappers.length - 1].gappersTickersWithNews;
          commit('setNewsGappers', newsGappers);
        }

        commit('setWeekGappers', weekGappers);
        commit('setGappers', todaysGappers);
        commit('setLoader', false);
      } catch (error) {
        commit('setNewsGappers', null);
        commit('setWeekGappers', null);
        commit('setGappers', null);
        commit('setLoader', false);
        if (!error.message.includes('No tickers found')) {
          console.error(`\nError had occur: ${error.message}\n`);
        }
      }
    },
    async fetchPrices({ commit }) {
      try {
        const isoDate = this.getters.getDate;
        const tickersObjects = this.getters.getGappers;
        let tickersForUri = '';
        tickersObjects.map((object) => {
          tickersForUri += object.ticker + ',';
        });
        tickersForUri = tickersForUri.slice(0, -1);
        const uri = `/prices/batch?date=${isoDate}&tickers=${tickersForUri}`;
        const tickersPrices = await tickersOperations.getPrices(
          this.getters,
          uri
        );
        commit('setPrices', tickersPrices);
      } catch (error) {
        commit('setPrices', null);
        console.error(`\nError had occur: ${error.message}\n`);
      }
    },
    async mapChartPrices({ commit }) {
      try {
        let assetPricesMapped;
        if (this.getters.getUserAsset.includes('stocks')) {
          const allPricesArray = this.getters.getPrices;
          const marketSettings = this.getters.getMarketSettings;
          allPricesArray.map((tickerPricesObject) => {
            const data = tickerPricesObject.data;
            const tickerPricesActualLabels = data
              .map((tickerPriceDataRecord) => {
                if (tickerPriceDataRecord.actual) {
                  const localTime = new Date(
                    tickerPriceDataRecord.id + '.000Z'
                  );
                  const date = localTime.toLocaleString(undefined, {
                    timeZone: marketSettings.timeLocale,
                  });
                  const time = date.split(', ')[1].split(' ')[0].slice(0, -3);
                  const amfm = date.split(', ')[1].split(' ')[1];
                  return amfm ? time + amfm : time;
                }
              })
              .filter((x) => x)
              .slice(0, 6);
            const tickerPricesActualValues = data
              .map((tickerPriceDataRecord) => {
                if (tickerPriceDataRecord.actual) {
                  return tickerPriceDataRecord.actual;
                }
              })
              .filter((x) => x)
              .map((x) => {
                return parseFloat(x);
              })
              .slice(0, 6);
            const tickerPricesPredictedLabels = data
              .map((tickerPriceDataRecord) => {
                if (tickerPriceDataRecord.predicted) {
                  const localTime = new Date(
                    tickerPriceDataRecord.id + '.000Z'
                  );
                  const date = localTime.toLocaleString(undefined, {
                    timeZone: marketSettings.timeLocale,
                  });
                  const time = date.split(', ')[1].split(' ')[0].slice(0, -3);
                  const amfm = date.split(', ')[1].split(' ')[1];
                  return amfm ? time + amfm : time;
                }
              })
              .filter((x) => x);
            const tickerPricesPredictedValues = data
              .map((tickerPriceDataRecord) => {
                if (tickerPriceDataRecord.predicted) {
                  return tickerPriceDataRecord.predicted;
                }
              })
              .filter((x) => x)
              .map((x) => {
                return parseFloat(x);
              });
            const mappedPriceObject = {
              actual: {
                labels: tickerPricesActualLabels,
                value: tickerPricesActualValues,
              },
              predicted: {
                labels: tickerPricesPredictedLabels,
                value: tickerPricesPredictedValues,
              },
            };
            const tickerPrice = {
              [tickerPricesObject.tickerName]: mappedPriceObject,
            };
            assetPricesMapped = { ...assetPricesMapped, ...tickerPrice };
          });
        } else {
          const marketSettings = this.getters.getMarketSettings;
          const userAsset = this.getters.getUserAsset;
          const assetsData = this.getters.getAssetsData[userAsset];
          Object.keys(assetsData).forEach((assetName) => {
            const assetData = assetsData[assetName];
            const assetPricesActualLabels = assetData.price
              .map((assetPriceDataRecord) => {
                if (assetPriceDataRecord.data.actual) {
                  const localTime = new Date(
                    assetPriceDataRecord.date + '.000Z'
                  );
                  const date = localTime.toLocaleString(undefined, {
                    timeZone: marketSettings.timeLocale,
                  });
                  const time = date.split(', ')[1].split(' ')[0].slice(0, -3);
                  const amfm = date.split(', ')[1].split(' ')[1];
                  return amfm ? time + amfm : time;
                }
              })
              .filter((x) => x);
            const assetPricesActualValues = assetData.price
              .map((assetPriceDataRecord) => {
                if (assetPriceDataRecord.data.actual) {
                  return assetPriceDataRecord.data.actual;
                }
              })
              .filter((x) => x)
              .map((x) => {
                return parseFloat(x);
              });
            const assetPricesPredictedLabels = assetData.price
              .map((assetPriceDataRecord) => {
                if (assetPriceDataRecord.data.predicted) {
                  const localTime = new Date(
                    assetPriceDataRecord.date + '.000Z'
                  );
                  const date = localTime.toLocaleString(undefined, {
                    timeZone: marketSettings.timeLocale,
                  });
                  const time = date.split(', ')[1].split(' ')[0].slice(0, -3);
                  const amfm = date.split(', ')[1].split(' ')[1];
                  return amfm ? time + amfm : time;
                }
              })
              .filter((x) => x);
            const assetPricesPredictedValues = assetData.price
              .map((assetPriceDataRecord) => {
                if (assetPriceDataRecord.data.predicted) {
                  return assetPriceDataRecord.data.predicted;
                }
              })
              .filter((x) => x)
              .map((x) => {
                return parseFloat(x);
              });

            const mappedPriceObject = {
              actual: {
                labels: assetPricesActualLabels,
                value: assetPricesActualValues,
              },
              predicted: {
                labels: assetPricesPredictedLabels,
                value: assetPricesPredictedValues,
              },
            };
            const assetPrice = {
              [assetData.ticker]: mappedPriceObject,
            };
            assetPricesMapped = { ...assetPricesMapped, ...assetPrice };
          });
        }
        // console.log(`\nassetPricesMapped: \n ${JSON.stringify(assetPricesMapped, null, 2)}\n`)
        commit('setPricesMapped', assetPricesMapped);
      } catch (error) {
        // Clean error as user changed market
        commit('setPricesMapped', null);
      }
    },
  },
};

export const tickersStore = store;
