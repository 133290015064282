const capitalize = (s) => {
  return s.toLowerCase().replace(/\b./g, function (a) {
    return a.toUpperCase();
  });
};

const getAddonValue = (encryptor) => {
  return encryptor.includes('userApi')
    ? (encryptor.length * (666 / 111)).toString()
    : (encryptor.length * (666 / 111)).toString() +
        new Date().getUTCDate() +
        new Date().getUTCHours();
};

const getQueryDateString = (queryDate) => {
  let yyyy = queryDate.getFullYear();
  let mm = queryDate.getMonth() + 1;
  let dd = queryDate.getDate();
  if (mm < 10) {
    mm = `0${mm}`;
  }
  if (dd < 10) {
    dd = `0${dd}`;
  }
  return yyyy + '-' + mm + '-' + dd;
};

const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export { capitalize, getAddonValue, getQueryDateString, randomNumber };
