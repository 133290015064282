const endpoint = '/assets';
import * as http from '../http';

const getCrypto = async (getters, uri) => {
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint + uri
    : baseUrl + endpoint + uri;
  const caller = await http.createCaller(fullUrl);
  return await http.get(caller, {});
};

export { getCrypto };
