import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

/* eslint-disable */
// https://www.materialpalette.com/blue-grey/deep-orange
export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: colors.blueGrey,
        secondary: colors.deepOrange,
        accent: colors.deepOrange,
        error: colors.red
      },
      dark: {
        primary: colors.blueGrey,
        secondary: colors.deepOrange,
        accent: colors.deepOrange,
        error: colors.red
      }
    },
    light: true,
    options: {
      customProperties: true,
    },
  }
});
