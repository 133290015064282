<template>
  <v-dialog v-model="signInDialogShown" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline"
          >{{ strings.header.appName }} {{ strings.header.sign_in }}</span
        >
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row justify="space-around">
            <v-icon large color="red">mdi-account-tie</v-icon>
            <v-text-field
              name="email"
              type="email"
              v-model="email"
              :rules="emailRules"
              onPaste="return false"
              onCopy="return false"
              onCut="return false"
              onDrag="return false"
              onDrop="return false"
              maxlength="260"
              required
            >
              <span slot="label">
                {{ strings.modals.sign_in_up.email_label }}
              </span>
            </v-text-field>
          </v-row>
          <v-row justify="space-around">
            <v-icon large color="red">mdi-key</v-icon>
            <v-text-field
              name="password"
              id="password"
              onPaste="return false"
              onCopy="return false"
              onCut="return false"
              onDrag="return false"
              onDrop="return false"
              maxlength="260"
              required
              v-model="password"
              :rules="passwordRules"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
            >
              <span slot="label">
                {{ strings.modals.sign_in_up.password_label }}
              </span>
            </v-text-field>
          </v-row>
          <v-row>
            <v-checkbox v-model="staySignedIn">
              <span slot="label">
                {{ strings.modals.sign_in_up.stay }}
              </span>
            </v-checkbox>
            <v-spacer></v-spacer>
            <v-btn
              text
              x-small
              color="primary"
              class="mt-5"
              :disabled="!valid"
              @click.stop="sendPasswordEmail"
              >Reset password</v-btn
            >
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined text @click.stop="closeDialog">{{
          strings.common.close
        }}</v-btn>
        <v-btn
          color="accent"
          depressed
          :disabled="!valid"
          @click.stop="submit"
          id="signInModal"
          >SignIn</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SignIn',
  props: {
    signInDialogShown: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      dialogShown: this.signInDialogShown,
      valid: false,
      email: '',
      password: '',
      showPassword: false,
      staySignedIn: false,
      emailRules: [
        (v) => !!v || this.strings.modals.sign_in_up.email_required,
        (v) =>
          /.+@.+/.test(v) || this.strings.modals.sign_in_up.email_not_valid,
        (v) =>
          !this.strings.modals.sign_in_up.email_not_allowed.some((substring) =>
            v.includes(substring)
          ) || this.strings.modals.sign_in_up.email_not_valid_quick,
        (v) =>
          !this.blockedEmails.some((substring) => v.includes(substring)) ||
          this.strings.modals.sign_in_up.email_not_valid_quick,
      ],
      passwordRules: [
        (v) => !!v || this.strings.modals.sign_in_up.password_required,
        (v) =>
          v.length >= 6 || this.strings.modals.sign_in_up.password_not_valid,
      ],
    };
  },
  computed: {
    strings() {
      return this.$store.getters.getStrings;
    },
    blockedEmails() {
      return this.$store.getters.getBlockEmails;
    },
  },
  mounted() {
    this.setBlockEmails();
  },
  methods: {
    setBlockEmails() {
      this.$store.dispatch('setBlockEmailDomains');
    },
    sendPasswordEmail() {
      this.$store.dispatch('sendPasswordEmail', this.email);
    },
    closeDialog() {
      this.dialogShown = false;
      this.$emit('update:signInDialogShown', this.dialogShown);
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('userSignIn', {
          email: this.email,
          password: this.password,
          settings: {
            staySignedIn: this.staySignedIn,
          },
        });
        this.closeDialog();
      }
    },
  },
};
</script>

<style scoped></style>
