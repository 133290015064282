import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/account',
      name: 'account',
      component: () =>
        import(/* webpackChunkName: "admin" */ './views/Account.vue'),
      meta: {
        title: 'Account - Softalp',
        metaTags: [
          {
            name: 'description',
            content:
              'Softalp user account page. Main account data and API key.',
          },
          {
            property: 'og:description',
            content:
              'Softalp user account page. Main account data and API key.',
          },
        ],
      },
    },
    {
      path: '/admin',
      name: 'admin',
      component: () =>
        import(/* webpackChunkName: "admin" */ './views/Admin.vue'),
      meta: {
        title: 'Admin - Softalp',
        metaTags: [
          {
            name: 'description',
            content:
              'Softalp administration page. Main site administration operations.',
          },
          {
            property: 'og:description',
            content:
              'Softalp administration page. Main site administration operations.',
          },
        ],
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () =>
        import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue'),
      meta: {
        title: 'Dashboard - Softalp',
        metaTags: [
          {
            name: 'description',
            content:
              'Softalp dashboard page. Main stock analysis data, news, influencers.',
          },
          {
            property: 'og:description',
            content:
              'Softalp dashboard page. Main stock analysis data, news, influencers.',
          },
        ],
      },
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'Softalp - Investor Daily 1-stop',
        metaTags: [
          {
            name: 'description',
            content: 'Softalp Home page. Main site info and operations.',
          },
          {
            property: 'og:description',
            content: 'Softalp Home page. Main site info and operations.',
          },
        ],
      },
    },
  ],
});
