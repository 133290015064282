<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center" class="outlined mb-5">
          <v-card id="investor_card" class="ma-3 pa-6" elevation="4" tile>
            <v-card-title class="mb-0 accent--text text-h4 font-weight-light">
              {{ strings.home.price.investor }}
            </v-card-title>
            <v-card-text>
              <hr />
              <br />
              <v-icon small color="green">mdi-check-outline</v-icon>
              {{ strings.home.price.web_dashboard }}
              <br />
              <v-icon small color="accent">mdi-close-outline</v-icon>
              {{ strings.home.price.endpoints_access }}
              <br />
              <v-icon small color="accent">mdi-close-outline</v-icon>
              {{ strings.home.price.own_service_integration }}
              <br />
              <br />
              <div class="text-h5 text-center">
                <span class="accent--text strikethrough-diagonal">$30</span
                >&nbsp;$10/MONTH
              </div>
              <div class="text-h5 text-center">OR</div>
              <div class="text-h5 text-center">
                <span class="accent--text strikethrough-diagonal">$300</span
                >&nbsp;$100/YEAR
              </div>
              <div class="text-center">* Lifetime fixed *</div>
              <div class="text-center">* Cancel anytime *</div>
              <br />
              <template v-if="isAuthenticated">
                <v-layout>
                  <v-btn color="accent" depressed block to="/dashboard">{{
                    strings.header.dashboard
                  }}</v-btn>
                </v-layout>
              </template>
              <template v-else>
                <v-layout>
                  <v-btn color="accent" depressed small to="/dashboard">{{
                    strings.header.demo
                  }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    outlined
                    @click.stop="signUpDialogShown = true"
                    :disabled="!signUpActive"
                    >{{ strings.header.subscribe }}</v-btn
                  >
                </v-layout>
              </template>
            </v-card-text>
          </v-card>
          <v-card id="developer_card" class="ma-3 pa-6" elevation="4" tile>
            <v-card-title class="mb-0 accent--text text-h4 font-weight-light">
              {{ strings.home.price.developer }}
            </v-card-title>
            <v-card-text>
              <hr />
              <br />
              <v-icon small color="green">mdi-check-outline</v-icon>
              {{ strings.home.price.web_dashboard }}
              <br />
              <v-icon small color="green">mdi-check-outline</v-icon>
              {{ strings.home.price.endpoints_access }}
              <br />
              <v-icon small color="accent">mdi-close-outline</v-icon>
              {{ strings.home.price.own_service_integration }}
              <br />
              <br />
              <div class="text-h5 text-center">
                <span class="accent--text strikethrough-diagonal">$80</span
                >&nbsp;$30/MONTH
              </div>
              <div class="text-h5 text-center">OR</div>
              <div class="text-h5 text-center">
                <span class="accent--text strikethrough-diagonal">$800</span
                >&nbsp;$300/YEAR
              </div>
              <div class="text-center">* Lifetime fixed *</div>
              <div class="text-center">* Cancel anytime *</div>
              <br />
              <template v-if="isAuthenticated">
                <v-layout>
                  <v-btn color="accent" depressed block to="/dashboard">{{
                    strings.header.dashboard
                  }}</v-btn>
                </v-layout>
              </template>
              <template v-else>
                <v-layout>
                  <v-btn color="accent" depressed small to="/dashboard">{{
                    strings.header.demo
                  }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    outlined
                    @click.stop="signUpDialogShown = true"
                    :disabled="!signUpActive"
                    >{{ strings.header.subscribe }}</v-btn
                  >
                </v-layout>
              </template>
            </v-card-text>
          </v-card>
          <v-card id="white_label_card" class="ma-3 pa-6" elevation="4" tile>
            <v-card-title class="mb-0 accent--text text-h4 font-weight-light">
              {{ strings.home.price.white_label }}
            </v-card-title>
            <v-card-text>
              <hr />
              <br />
              <v-icon small color="green">mdi-check-outline</v-icon>
              {{ strings.home.price.web_dashboard }}
              <br />
              <v-icon small color="green">mdi-check-outline</v-icon>
              {{ strings.home.price.endpoints_access }}
              <br />
              <v-icon small color="green">mdi-check-outline</v-icon>
              {{ strings.home.price.own_service_integration }}
              <br />
              <br />
              <div class="text-h5 text-center">CONTACT</div>
              <div class="text-h5 text-center">FOR</div>
              <div class="text-h5 text-center">QUOTE</div>
              <div class="text-center">* Lifetime fixed *</div>
              <div class="text-center">* Cancel anytime *</div>
              <br />
              <template v-if="isAuthenticated">
                <v-layout>
                  <v-btn color="accent" depressed block to="/dashboard">{{
                    strings.header.dashboard
                  }}</v-btn>
                </v-layout>
              </template>
              <template v-else>
                <v-layout>
                  <v-btn color="accent" depressed small to="/dashboard">{{
                    strings.header.demo
                  }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    outlined
                    @click.stop="signUpDialogShown = true"
                    :disabled="!signUpActive"
                    >{{ strings.header.subscribe }}</v-btn
                  >
                </v-layout>
              </template>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
    <SignUp :signUpDialogShown.sync="signUpDialogShown" />
  </v-container>
</template>

<script>
import SignUp from '@/components/modals/SignUp';

export default {
  name: 'Price',
  components: {
    SignUp,
  },
  data() {
    return {
      signUpDialogShown: false,
      signUpActive: false,
    };
  },
  computed: {
    siteSettings() {
      return this.$store.getters.getSiteSettings;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isVerified() {
      return this.$store.getters.isUserVerified;
    },
    strings() {
      return this.$store.getters.getStrings;
    },
  },
  watch: {
    siteSettings() {
      this.setSiteSettings();
    },
  },
  methods: {
    setSiteSettings() {
      this.signUpActive = this.siteSettings.signUp;
    },
  },
  mounted() {
    this.siteSettings && this.setSiteSettings();
  },
};
</script>

<style scoped>
.outlined {
  opacity: 0.85;
}
.strikethrough-diagonal {
  position: relative;
}
.strikethrough-diagonal:before {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-color: inherit;

  -webkit-transform: rotate(-30deg);
  -moz-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  -o-transform: rotate(-30deg);
  transform: rotate(-30deg);
}
</style>
