const store = {
  state: {
    appName: 'Softalp',
    localTime: new Date(),
    baseUrl:
      window.location.href.split('//')[0] +
      `//` +
      window.location.href.split('//')[1].split('/')[0],
    environment: 'staging',
    browserLocalesTranslated: ['de', 'en', 'fr', 'hi', 'jp', 'zh'],
    browserLocale: 'en',
    companyName: 'Softalp Inc.',
    loading: false,
    confirmDialog: null,
    nextDialog: null,
    strings: null,
    blockEmails: null,
    snackbarShown: false,
    snackbarSettings: {
      timeout: 3000,
      top: true,
      right: true,
    },
    marketSettings: {
      flag: 'en',
      timeLocale: 'America/New_York',
    },
  },
  mutations: {
    setLocalTime(state, payload) {
      state.localTime = payload;
    },
    setBaseUrl(state, payload) {
      state.baseUrl = payload;
    },
    setEnvironment(state, payload) {
      state.environment = payload;
    },
    setBrowserLocale(state, payload) {
      state.browserLocale = payload;
    },
    setLoader(state, payload) {
      state.loading = payload;
    },
    setConfirmDialog(state, payload) {
      state.confirmDialog = payload;
    },
    setNextDialog(state, payload) {
      state.nextDialog = payload;
    },
    setStrings(state, payload) {
      state.strings = payload;
    },
    setBlockEmails(state, payload) {
      state.blockEmails = payload;
    },
    setSnackbarShow(state, payload) {
      state.snackbarShown = payload;
    },
    setSnackbarSettings(state, payload) {
      state.snackbarSettings = payload;
    },
    setMarketSettings(state, payload) {
      state.marketSettings = payload;
    },
  },
  // https://docs.vuestorefront.io/guide/vuex/vuex-conventions.html#getters
  getters: {
    getAppName: (state) => {
      return state.appName;
    },
    getLocalTime: (state) => {
      return state.localTime;
    },
    getBaseUrl: (state) => {
      return state.baseUrl;
    },
    getEnvironment: (state) => {
      return state.environment;
    },
    getBrowserLocale: (state) => {
      return state.browserLocale;
    },
    getBrowserLocalesTranslated: (state) => {
      return state.browserLocalesTranslated;
    },
    getCompanyName: (state) => {
      return state.companyName;
    },
    isLoading: (state) => {
      return state.loading;
    },
    getConfirmDialog: (state) => {
      return state.confirmDialog;
    },
    getNextDialog: (state) => {
      return state.nextDialog;
    },
    getStrings: (state) => {
      return state.strings;
    },
    getBlockEmails: (state) => {
      return state.blockEmails;
    },
    isSnackbarShown: (state) => {
      return state.snackbarShown;
    },
    getSnackbarSettings: (state) => {
      return state.snackbarSettings;
    },
    getMarketSettings: (state) => {
      return state.marketSettings;
    },
  },
  // https://docs.vuestorefront.io/guide/vuex/vuex-conventions.html#actions
  actions: {
    setCurrentMarketSettings({ commit }) {
      const userMarket = this.getters.getUserMarket;
      let values;
      switch (userMarket) {
        case 'america':
          values = {
            flag: 'us',
            timeLocale: 'America/New_York',
          };
          break;
        case 'england':
          values = {
            flag: 'en',
            timeLocale: 'Europe/London',
          };
          break;
        case 'europe':
          values = {
            flag: 'eu',
            timeLocale: 'Europe/Zurich',
          };
          break;
        case 'india':
          values = {
            flag: 'hi',
            timeLocale: 'Asia/Kolkata',
          };
          break;
        case 'china':
          values = {
            flag: 'zh',
            timeLocale: 'Asia/Shanghai',
          };
          break;
        case 'japan':
          values = {
            flag: 'jp',
            timeLocale: 'Asia/Tokyo',
          };
          break;
        default:
          values = {
            flag: 'en',
            timeLocale: 'America/New_York',
          };
          break;
      }
      commit('setMarketSettings', values);
    },
    async setLocalTime({ commit }) {
      setInterval(() => {
        commit('setLocalTime', new Date());
      }, 60 * 1000);
    },

    async setBaseUrl({ commit }) {
      let baseUrl = this.getters.getBaseUrl;
      if (baseUrl.includes('localhost:8085')) {
        baseUrl += '/softalp-sls/us-central1';
        baseUrl = baseUrl.replace('8085', '5000');
      }
      commit('setBaseUrl', baseUrl);
    },

    async getMarketTime({ commit }, market) {
      switch (market) {
        case 'america':
          return this.getters.getLocalTime.toLocaleString('en-US', {
            timeZone: 'America/New_York',
          });
        case 'england':
          return this.getters.getLocalTime.toLocaleString('en-US', {
            timeZone: 'Europe/London',
          });
        case 'europe':
          return this.getters.getLocalTime.toLocaleString('en-US', {
            timeZone: 'Europe/Zurich',
          });
        case 'india':
          return this.getters.getLocalTime.toLocaleString('en-US', {
            timeZone: 'Asia/Kolkata',
          });
        case 'china':
          return this.getters.getLocalTime.toLocaleString('en-US', {
            timeZone: 'Asia/Shanghai',
          });
        case 'japan':
          return this.getters.getLocalTime.toLocaleString('en-US', {
            timeZone: 'Asia/Tokyo',
          });
        default:
          return this.getters.getLocalTime.toLocaleString('en-US', {
            timeZone: 'America/New_York',
          });
      }
    },

    async nextDialog({ commit }, value) {
      commit('setNextDialog', value);
    },

    async browserLocale({ commit }, value) {
      commit('setBrowserLocale', value);
    },

    async strings({ commit }) {
      const locale = this.getters.getBrowserLocale;
      let strings;
      switch (locale) {
        case 'de':
          // strings = require(`@/assets/locales/de/strings.js`);
          break;
        case 'en':
          strings = require(`@/assets/locales/en/strings.js`);
          break;
        case 'fr':
          // strings = require(`@/assets/locales/fr/strings.js`);
          break;
        case 'hi':
          // strings = require(`@/assets/locales/hi/strings.js`);
          break;
        case 'jp':
          // strings = require(`@/assets/locales/jp/strings.js`);
          break;
        case 'zh':
          // strings = require(`@/assets/locales/zh/strings.js`);
          break;
        default:
          strings = require(`@/assets/locales/en/strings.js`);
      }
      commit('setStrings', strings.default);
    },

    async setBlockEmailDomains({ commit }) {
      commit('setBlockEmails', require('disposable-email-domains'));
    },

    async showSnackbarAction({ commit }, { status, settings }) {
      const newSettings = { ...this.getters.getSnackbarSettings, ...settings };
      commit('setSnackbarShow', status);
      commit('setSnackbarSettings', newSettings);
      setTimeout(() => {
        commit('setSnackbarShow', false);
        // 'success', 'info', 'error'
        commit('setSnackbarSettings', {
          timeout: 3000,
          top: true,
          right: true,
        });
      }, newSettings.timeout - 100);
    },

    async showConfirmDialogAction({ commit }, settings) {
      commit('setConfirmDialog', settings);
    },

    async setEnvironment({ commit }) {
      let url = window.location.href.split('//')[1].split('/')[0];
      let currentEnvironment;
      switch (true) {
        case url.includes('localhost'):
          currentEnvironment = 'staging';
          break;
        case url.includes('app.softalp') || url.includes('softalp-sls'):
          currentEnvironment = 'staging';
          break;
        case !url.includes('localhost') &&
          !url.includes('app.softalp') &&
          !url.includes('softalp-sls') &&
          (url.includes('softalp.com') || url.includes('softalp-prod')):
          currentEnvironment = 'production';
          break;
        default:
          currentEnvironment = 'staging';
          break;
      }
      commit('setEnvironment', currentEnvironment);
    },
  },
};

export const generalStore = store;
