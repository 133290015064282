getAssetDataMapped = async (assetData) => {
  let assetDataMapped = [];

  Object.keys(assetData).forEach((assetName) => {
    let assetRecord = assetData[assetName];
    let assetStatRecord = {};
    assetStatRecord = assetRecord.stat[assetRecord.stat.length - 1].data;

    let assetObject = {
      symbolName: assetStatRecord.assetNameFull,
      gapPercent: assetStatRecord.assetPriceChange,
      lastPrice: assetStatRecord.assetPrice,
      volume: '00000',
      ticker: assetName,
      mood: assetRecord.mood,
      news: assetRecord.news,
      price: assetRecord.price,
      stat: assetStatRecord,
    };
    assetDataMapped.push(assetObject);
  });
  return assetDataMapped;
};

getCryptoDataMapped = async (cryptoData) => {
  let cryptoDataMapped = [];

  cryptoData.forEach((cryptoObjectRaw) => {
    let cryptoObject = {
      gapPercent: cryptoObjectRaw.change24h,
      lastPrice: cryptoObjectRaw.price.replace('$', ''),
      symbolName: cryptoObjectRaw.name.replace(
        cryptoObjectRaw.symbol,
        cryptoObjectRaw.symbol + '-'
      ),
      ticker: cryptoObjectRaw.symbol,
      volume: cryptoObjectRaw.volume.replace('$', '').replace(/,/g, ''),
    };
    cryptoDataMapped.push(cryptoObject);
  });
  return cryptoDataMapped;
};

module.exports = {
  getAssetDataMapped,
  getCryptoDataMapped,
};
