<template>
  <v-dialog v-model="privacyDialogShown" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline"
          >{{ strings.header.appName }}
          {{ strings.modals.privacy.header }}</span
        >
        <v-spacer></v-spacer>
        <v-icon color="red" @click.stop="closeDialog">mdi-close-circle</v-icon>
      </v-card-title>
      <v-card-text>
        <div v-html="strings.modals.privacy.text"></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.stop="closeDialog">{{
          strings.common.close
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Privacy',
  props: {
    privacyDialogShown: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      dialogShown: this.privacyDialogShown,
    };
  },
  computed: {
    strings() {
      return this.$store.getters.getStrings;
    },
  },
  methods: {
    closeDialog() {
      this.dialogShown = false;
      this.$emit('update:privacyDialogShown', this.dialogShown);
    },
  },
};
</script>

<style scoped></style>
