const endpoint = '/payments';
import * as http from '../http';

const listStripeData = async (getters, parameters) => {
  const uri = '/list';
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint + uri
    : baseUrl + endpoint + uri;
  const caller = await http.createCaller(fullUrl);
  return await http.get(caller, parameters);
};

const createCustomer = async (getters, uri) => {
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint + uri
    : baseUrl + endpoint + uri;
  const customerCaller = await http.createCaller(fullUrl);
  const customerPayload = {
    email: getters.getUserEmail,
  };
  return await http.post(customerCaller, customerPayload);
};

const retrieveCustomer = async (getters, uri) => {
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint + uri
    : baseUrl + endpoint + uri;
  const customerCaller = await http.createCaller(fullUrl);
  const parameters = {
    email: getters.getUserEmail,
  };
  return await http.get(customerCaller, parameters);
};

const getAsset = async (getters, parameters) => {
  const uri = '/assets';
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint + uri
    : baseUrl + endpoint + uri;
  const caller = await http.createCaller(fullUrl);
  return await http.get(caller, parameters);
};

const createSubscription = async (getters, subscriptionPayload) => {
  const uri = '/subscriptions';
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint + uri
    : baseUrl + endpoint + uri;
  const subscriptionCaller = await http.createCaller(fullUrl);
  return await http.post(subscriptionCaller, subscriptionPayload);
};

const deleteSubscription = async (getters, subscriptionPayload) => {
  const uri = '/delete';
  const baseUrl = getters.getBaseUrl;
  const fullUrl = baseUrl.includes('localhost:5000')
    ? baseUrl + endpoint + endpoint + uri
    : baseUrl + endpoint + uri;
  const subscriptionCaller = await http.createCaller(fullUrl);
  return await http.DELETE(subscriptionCaller, subscriptionPayload);
};

const handleCustomerActionRequired = async (payload) => {
  let stripe = payload.stripe;
  let subscription = payload.subscription;

  if (subscription && subscription.status === 'active') {
    return payload;
  }

  let paymentIntent = subscription.latest_invoice.payment_intent;

  if (paymentIntent.status === 'requires_action') {
    return stripe
      .confirmCardPayment(paymentIntent.client_secret, {
        payment_method: paymentIntent.payment_method,
      })
      .then((result) => {
        if (result.error) {
          // start code flow to handle updating the payment details
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc)
          throw result;
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            subscription.latest_invoice.payment_intent = result.paymentIntent;
            return {
              ...payload,
              ...{ subscription: subscription },
            };
          }
        }
      });
  } else {
    // No customer action needed
    return payload;
  }
};

const handlePaymentMethodRequired = async (payload) => {
  let subscription = payload.subscription;
  if (subscription && subscription.status === 'active') {
    return payload;
  } else if (
    subscription.latest_invoice.payment_intent.status ===
    'requires_payment_method'
  ) {
    throw { error: { message: 'Your card was declined!' } };
  } else {
    return payload;
  }
};

export {
  listStripeData,
  createCustomer,
  retrieveCustomer,
  getAsset,
  createSubscription,
  deleteSubscription,
  handleCustomerActionRequired,
  handlePaymentMethodRequired,
};
