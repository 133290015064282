import * as adminOperations from '../utils/operations/admins';
import * as userOperations from '../utils/operations/users';
import * as helpers from '../utils/helpers';

const store = {
  state: {
    statisticNews: null,
    statisticPrices: null,
    siteSettings: null,
    users: null,
    feedbacks: null,
  },
  mutations: {
    setStatisticNews(state, payload) {
      state.statisticNews = payload;
    },
    setStatisticPrices(state, payload) {
      state.statisticPrices = payload;
    },
    setSiteSettings(state, payload) {
      state.siteSettings = payload;
    },
    setUsers(state, payload) {
      state.users = payload;
    },
    setFeedbacks(state, payload) {
      state.feedbacks = payload;
    },
  },
  getters: {
    getStatisticNews: (state) => {
      return state.statisticNews;
    },
    getStatisticPrices: (state) => {
      return state.statisticPrices;
    },
    getSiteSettings: (state) => {
      return state.siteSettings;
    },
    getUsers: (state) => {
      return state.users;
    },
    getFeedbacks: (state) => {
      return state.feedbacks;
    },
  },
  actions: {
    async getStatisticNewsRecords({ commit }, sinceDate) {
      const statisticNews = await adminOperations.getStatisticNews(
        this.getters,
        helpers.getQueryDateString(sinceDate)
      );
      commit('setStatisticNews', statisticNews);
    },
    async getStatisticPricesRecords({ commit }, tickersByDate) {
      let tickersPrices = {};
      const dates = Object.keys(tickersByDate);
      for (const date of dates) {
        const tickerNames = tickersByDate[date].map((ticker) => {
          return ticker.split('-')[0];
        });
        const tickers = tickerNames.join();
        tickersPrices[date] = await adminOperations.getStatisticPrices(
          this.getters,
          date,
          tickers
        );
      }
      commit('setStatisticPrices', tickersPrices);
    },
    async getSiteSettingsRecords({ commit }) {
      const settings = await adminOperations.getSettings(this.getters);
      commit('setSiteSettings', settings.data);
    },
    async setSiteSettingsRecords({ commit, dispatch }, settings) {
      try {
        await adminOperations.setSettings(this.getters, settings);
        dispatch('getSiteSettingsRecords');
      } catch (error) {
        console.error(`\nError had occur: ${error.message}\n`);
      }
    },
    async getUsersRecords({ commit }) {
      const users = await userOperations.getUsers(this.getters);
      commit('setUsers', users.data);
    },
    async getFeedbackRecords({ commit }) {
      const feedbacks = await userOperations.getFeedbacks(this.getters);
      commit('setFeedbacks', feedbacks.data);
    },
  },
};

export const adminsStore = store;
