import * as cryptoOperations from '../utils/operations/crypto';
import * as cryptoMapping from '../utils/mapper';

const store = {
  state: {
    cryptoData: null,
  },
  mutations: {
    setCryptoData(state, payload) {
      state.cryptoData = payload;
    },
  },
  getters: {
    getCryptoData: (state) => {
      return state.cryptoData;
    },
  },
  actions: {
    async fetchCryptoData({ commit }) {
      const uriGappers = `/gappers/crypto/gappers`;
      const uriLists = `/gappers/crypto/lists`;
      try {
        const cryptoGappersResponse = await cryptoOperations.getCrypto(
          this.getters,
          uriGappers
        );
        const cryptoListsResponse = await cryptoOperations.getCrypto(
          this.getters,
          uriLists
        );

        // Filter list results by 1h 24h 7d trend
        const latestListRecord =
          cryptoListsResponse.data[cryptoListsResponse.data.length - 1];
        let cryptoList = latestListRecord[latestListRecord.id];
        console.log(
          `Total crypto for timestamp ${latestListRecord.id}: ${cryptoList.length}`
        );

        // Filter tradable assets
        let cryptoListPlus = cryptoList.filter((cryptoObject) => {
          return (
            cryptoObject.change1h.includes('+') &&
            cryptoObject.change7d.includes('+') &&
            cryptoObject.change24h.includes('+')
          );
        });
        let cryptoListMinus = cryptoList.filter((cryptoObject) => {
          return (
            cryptoObject.change1h.includes('-') &&
            cryptoObject.change7d.includes('-') &&
            cryptoObject.change24h.includes('-')
          );
        });
        const cryptoListPlusSymbols = cryptoListPlus.map((object) => {
          return object.symbol;
        });
        const cryptoListMinusSymbols = cryptoListMinus.map((object) => {
          return object.symbol;
        });
        console.log(
          `Total crypto after "all plus" filter ${latestListRecord.id}: ${cryptoListPlus.length}`
        );
        console.log(
          `Total crypto after "all minus" filter ${latestListRecord.id}: ${cryptoListMinus.length}`
        );
        console.log(
          `Total crypto plus symbols ${cryptoListPlusSymbols.length}`
        );
        console.log(
          `Total crypto minus symbols ${cryptoListMinusSymbols.length}`
        );
        console.log(
          `------------------------------------------------------------------------------------------------------`
        );

        cryptoListPlus = await cryptoMapping.getCryptoDataMapped(
          cryptoListPlus
        );

        cryptoListMinus = await cryptoMapping.getCryptoDataMapped(
          cryptoListMinus
        );

        commit('setCryptoData', {
          cryptoGappers: cryptoGappersResponse,
          cryptoLists: { cryptoListPlus, cryptoListMinus },
        });
      } catch (error) {
        if (!error.message.includes('No crypto gappers data found')) {
          console.error(`\nError had occur: ${error.message}\n`);
        }
      }
    },
  },
};

export const cryptoStore = store;
