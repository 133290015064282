export default {
  common: {
    close: `Close`,
    confirm: `Confirm`,
    cancel: `Cancel`,
  },
  header: {
    title: `Softalp - Investor Daily 1-stop`,
    appName: `Softalp`,
    demo: `Demo`,
    subscribe: `Subscribe`,
    sign_in: `Sign In`,
    sign_up: `Sign Up`,
    sign_out: `Sign Out`,
    dashboard: `Dashboard`,
    account: `Account`,
    admin: `Admin`,
  },
  footer: {
    cookies: `Cookies`,
    privacy: `Privacy`,
    terms: `Terms`,
    companyName: `SoftAlp Inc.`,
    companyTown: `Palo Alto, CA`,
  },
  home: {
    slogan: {
      first: `<div class="display-3 font-weight-black text-center mb-8">
      <span class="accent--text">+</span>STOCKS<span class="accent--text">+</span>
      <span class="accent--text">+</span>CRYPTO<span class="accent--text">+</span>
      <br>
      METALS, FOREX, ETFs
    </div>`,
      second: `
      <div class="display-1 font-weight-bold text-center mb-8">
      &bull; Gathering News For Top 5 Tradable Assets <span class="accent--text">&#8617;</span><br>
      &bull; Analyze News Sentiment with AI & NLP: <span class="accent--text">&#8617;</span><br>
      &bull; Artificial Intelligence and Natural <span class="accent--text">&#8617;</span><br>
      &bull; Language Processing <span class="accent--text">&#8617;</span><br>
      </div>`,
      third: `
      <div class="display-1 font-weight-bold text-center mt-8">
      &bull; Eliminated Human Factor Forecast for just <span class="accent--text">¢33/day</span> 
      </div>
      <div class="display-1 font-weight-bold text-center mt-8">
      <span class="accent--text">++AUTUMN PRICES FALL++</span> 
      </div>`,
    },
    price: {
      investor: `Investor`,
      developer: `Developer`,
      white_label: `White Label`,
      web_dashboard: `Web Dashboard`,
      endpoints_access: `API Endpoints Access`,
      own_service_integration: `Own Service Integration`,
      price_by_release: `Price by Release Status`,
      current: `Current`,
      service_lifetime: `* Service Lifetime Fixed Price`,
    },
  },
  dashboard: {
    markets: `Markets`,
    card: {
      gapping_percent: `Change`,
      gapping_price: `Price`,
      gapping_volume: `Volume`,
    },
    news: `News`,
  },
  account: {
    subscription: `Subscription`,
    package: `Package`,
    billing: `Billing`,
    cards: `Cards`,
    secure_powered_by: `Secure powered by`,
    key: `Key`,
    endpoints: `Endpoints`,
  },
  modals: {
    cookies: {
      popup: {
        text: `
        Your consent regarding using cookies on this site. Service uses Google Authentication
        and Analytics cookies only.
        `,
        link: `Learn More.`,
        disagree_button: `Disagree`,
        agree_button: `Agree`,
      },
      header: `Cookies Policy`,
      text: `
      <p>
      Softalp service use a variety of technologies to help us to deliver
      customized visitor experiences. In particular, we may use a technology
      called "cookies" to provide you with, for example, customized
      information from our website and its webpages (the "Site"). By
      agreeing to this Cookies Policy you consent to the use of cookies by
      us as described in this Cookies Policy.
    </p>
    <p>
      A cookie is an element of data that a website can send to your
      browser, which may then store it on your system. Cookies allow us to
      understand who has seen which pages and advertisements, to determine
      how frequently particular pages are visited and to determine the most
      popular areas of our Site. Non personal information such as browser
      type, operating system and domain names may be collected during
      visitors' use of the Site and this information may be used by us to
      measure the number of visitors to the Site.
    </p>
    <h3>What types of cookies do we use?</h3>
    <p>We may use three types of cookies on the Site:</p>
    <p>
      <strong>Session cookies</strong> – these are temporary cookies that
      remain in the cookie file of your browser until you close the browser.
      This cookie is automatically onto your browser by the server software,
      but it is not used by our website.
    </p>
    <p>
      <strong>Analytical cookies</strong> – Our website uses third party
      cookies, such as Google Analytics cookies (third party cookies) for
      the following purposes:
    </p>
    <ul>
      <li>
        a cookie to record the time of your first visit to website, the time
        of your most recent visit to the website and the time of your
        current visit;
      </li>
      <li>a cookie to record which page(s) you visit on the website;</li>
      <li>a cookie to record how long you stayed on the website; and</li>
      <li>
        a cookie to record how you located our website (i.e. Google search,
        keyword, link from other page etc.).
      </li>
    </ul>
    <p>
      We only share the information obtained through the use of Google
      Analytics with Google. For more information on Google's use of your
      information, please see
      <a
        href="https://www.google.com/policies/technologies/types/"
        target="_blank"
        >Types of Cookies Used by Google</a
      >. The cookies we use for these purposes do not track your Internet
      usage after leaving our website and do not store your personal
      information. They will not be used in connection with any other
      information to identify you in any way.
    </p>
    <h3>Disabling cookies</h3>
    <p>
      You can adjust your browser to tell you when a website tries to put a
      cookie on your computer.
    </p>
    <p>
      How you adjust your browser to stop it accepting cookies or to notify
      you of them, will depend on the type of internet browser programme
      your computer uses.
      <a href="https://www.aboutcookies.org/page/2/" target="_blank"
        >You can follow the appropriate instructions for your specific
        browser type</a
      >
      (please note that this link will open a new window and will lead to an
      external website and that we are not responsible for the content of
      external websites).
    </p>
    <h3>How to disable third party cookies</h3>
    <p>
      Users based in the European Union can
      <a href="https://www.youronlinechoices.eu/" target="_blank"
        >opt out of these third party cookies</a
      >. This website is not connected us and we are not responsible for its
      content. We only keep cookies for the duration of your visit to our
      Site, except in the case of cookies which remember you for future
      visits or where you save your login name as referred to above.
    </p>
    <h3>Your consent to cookies</h3>
    <p>
      By agreeing to this policy you consent to the use of cookies by us as
      described above.
      <a href="https://www.aboutcookies.org" target="_blank"
        >Read more about cookies</a
      >
      (but please remember that we are not responsible for external
      websites).
    </p>
    <h3>Legal Notice Update</h3>
    <p>
      We reserve the right to make any changes and corrections to this
      notice. Please refer to this page from time to time to review these
      and new additional information.
    </p>
    <p>
      Cookies do not contain confidential information such as your home
      address, telephone number or credit card details. We do not exchange
      cookies with any third party websites or external data suppliers. If
      you do choose to disable cookies, you may find that certain sections
      of our Site do not work properly.
    </p>
      `,
    },
    privacy: {
      header: `Privacy Policy`,
      text: `
      <p>
      You can generally use softalp.com (Softalp Inc.) without revealing any
      Personally Identifiable Information about yourself. In any case
      softalp.com is committed to protect your privacy and take your trust
      very seriously, and make it our highest priority to ensure the
      security and confidentiality of the Personally Identifiable
      Information you provide us. Only Personally Identifiable Information
      service is collecting and stored is an email address. It's used then
      in Google service for authorization purposes. Also cookies, baked by Google, 
      (see link in footer for more information) are stored in your computer. Firstly - to keep you logged in, 
      secondly - for analytical purposes to give you best service based on statistics.
    </p>
      `,
    },
    email: {
      header: `Email Verification`,
      text: `
      <p>
      Email sent to verify address!
    </p>
      `,
    },
    sign_in_up: {
      accept_privacy: `I accept privacy policy.`,
      accept_terms: `I accept terms.`,
      accept_more_information: `More information.`,
      stay: `Stay Signed In`,
      email_label: `Email`,
      email_required: `E-mail is required`,
      email_not_valid: `E-mail must be valid`,
      email_not_valid_quick: `E-mail must be real`,
      email_not_allowed: [
        'gishpuppy',
        'mintinbox',
        'mintemail',
        'mailnesia',
        'trbvm',
        'filzmail',
        'wimsg',
        'minuteinbox',
        'minutemail',
        'andsee',
        'e4ward',
        'awdrt',
        'laste',
        'jupiterm',
        'maildrop',
        'zzrgg',
        'jetable',
        'mailinator',
        'spamgourmet',
        'cowboy',
        'nada',
        'yopmail',
        'jetable',
        'nomail',
        'moncourrier',
        'monemail',
        'matra',
        'emailmonkey',
        'sharklasers',
        'guerrillamail',
        'grr',
        'pokemail',
        'spam',
      ],
      password_label: `Password`,
      password_required: `Password is required`,
      password_not_valid: `Password must be greater than 6 characters`,
      privacy_rule: `Sorry, you need to accept privacy policy to use service`,
      terms_rule: `Sorry, you need to accept terms to use service`,
    },
    terms: {
      header: `Terms of Use`,
      text: `
      <h3>What Softalp Is</h3>
        <p>
          Service is selling computed data and analytical coefficients output,
          calculated by own developed software with help of NLP (natural
          language processing) results from multiply sources. Processing made based on texts
          of market related news. That's it. Nothing more, nothing less. How
          user will interpret and use this data is their own responsibility.
        </p>
        <h3>What Softalp Is Not</h3>
        <p>
          It is not a financial or legal platform. It is not a brokerage
          service. Computational output data cannot be perceived as financial advice.
          Moreover, values represented in output data are in "default conventional units" (DCU) 
          and do not represent any kind of currency.
        </p>
        <h3>Service End</h3>
        <p>
          Service leave right to stop providing services at any time with 7 days
          notice. Existing users will be receiving data till the end of their current billing period.
        </p>
        <h3>Refund</h3>
        <p>
        You can choose between monthly or yearly subscription billing types. 
        You can always cancel both subscription types at any time. Please note, that
        monthly subscription is starting from first minute of service usage, thus refund for current
        billing period will be calculated on pro rata basis. Yearly subscription based on monthly subscription billing cycle.
        It is refundable also on pro rata basis with subtraction of monthly subscription usage.
        </p>
      `,
    },
    news_details: {
      magnitude: `Magnitude`,
      coefficient: `Coefficient`,
    },
  },
};
