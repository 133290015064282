import router from '@/router';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import validator from 'validator';
import { setUserClaims } from '../utils/operations/admins';
import * as userOperations from '../utils/operations/users';
import { encrypt } from '../utils/crypto';
import * as helpers from '../utils/helpers';
import * as auth from '../utils/auth';

// NOTE
// 1. Firebase Auth profile has priority in sync one way with db user profile
// 2. User meta data is picked up from db as might be heavy to hold it in firebase auth
// 3. userPackage and userRole logic is explained in USERS.md
const store = {
  state: {
    isAuthenticated: false,
    user: null,
    userClaims: null,
    userName: null,
    userEmail: null,
    userAccess: null,
    userApiCalls: null,
    userApiKey: null,
    userMarket: 'america',
    userAsset: 'stocks',
    userPaid: false,
    userPackage: 0,
    userRole: null,
    userSettings: null,
    userVerified: false,
    userDatabaseRecord: null,
    userTrialDays: null,
    userReady: false,
    userSnapshot: null,
    userDataChanged: false,
  },
  mutations: {
    setIsAuthenticated(state, payload) {
      state.isAuthenticated = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setUserClaims(state, payload) {
      state.userClaims = payload;
    },
    setUserName(state, payload) {
      state.userName = payload;
    },
    setUserEmail(state, payload) {
      state.userEmail = payload;
    },
    setUserAccess(state, payload) {
      state.userAccess = payload;
    },
    setUserApiCalls(state, payload) {
      state.userApiCalls = payload;
    },
    setUserApiKey(state, payload) {
      state.userApiKey = payload;
    },
    setUserMarket(state, payload) {
      state.userMarket = payload;
    },
    setUserAsset(state, payload) {
      state.userAsset = payload;
    },
    setUserPaid(state, payload) {
      state.userPaid = payload;
    },
    setUserPackage(state, payload) {
      state.userPackage = payload;
    },
    setUserRole(state, payload) {
      state.userRole = payload;
    },
    setUserSettings(state, payload) {
      state.userSettings = payload;
    },
    setUserVerified(state, payload) {
      state.userVerified = payload;
    },
    setUserDatabaseRecord(state, payload) {
      state.userDatabaseRecord = payload;
    },
    setUserTrialDays(state, payload) {
      state.userTrialDays = payload;
    },
    setUserReady(state, payload) {
      state.userReady = payload;
    },
    setUserSnapshot(state, payload) {
      state.userSnapshot = payload;
    },
    setUserDataChanged(state, payload) {
      state.userDataChanged = payload;
    },
  },
  // https://docs.vuestorefront.io/guide/vuex/vuex-conventions.html#getters
  getters: {
    isAuthenticated: (state) => {
      return state.user !== null && state.user !== undefined;
    },
    getUser: (state) => {
      return state.user;
    },
    getUserClaims: (state) => {
      return state.userClaims;
    },
    getUserName: (state) => {
      return state.userName;
    },
    getUserEmail: (state) => {
      return state.userEmail;
    },
    getUserAccess: (state) => {
      return state.userAccess;
    },
    getUserApiCalls: (state) => {
      return state.userApiCalls;
    },
    getUserApiKey: (state) => {
      return state.userApiKey;
    },
    getUserMarket: (state) => {
      return state.userMarket;
    },
    getUserAsset: (state) => {
      return state.userAsset;
    },
    isUserPaid: (state) => {
      return state.userPaid;
    },
    getUserPackage: (state) => {
      return state.userPackage;
    },
    getUserRole: (state) => {
      return state.userRole;
    },
    getUserSettings: (state) => {
      return state.userSettings;
    },
    isUserVerified: (state) => {
      return state.userVerified;
    },
    getUserDatabaseRecord: (state) => {
      return state.userDatabaseRecord;
    },
    getUserTrialDays: (state) => {
      return state.userTrialDays;
    },
    isUserReady: (state) => {
      return state.userReady;
    },
    getUserSnapshot: (state) => {
      return state.userSnapshot;
    },
    isUserDataChanged: (state) => {
      return state.userDataChanged;
    },
  },
  // https://docs.vuestorefront.io/guide/vuex/vuex-conventions.html#actions
  actions: {
    async userSignUp({ commit, dispatch }, { email, password, settings }) {
      if (!email.startsWith('maximkrut+') && !email.includes('@gmail.com')) {
        email = validator.normalizeEmail(email, {
          gmail_remove_subaddress: true,
          outlookdotcom_remove_subaddress: true,
          yahoo_remove_subaddress: true,
          icloud_remove_subaddress: true,
        });
      }
      await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async (user) => {
          const userName = await auth.getUserNameFromEmail(user.user.email);
          // Initial user claims to be set in Firebase Auth
          let claims = {
            userName: userName,
            userEmail: user.user.email,
            userAccess: 1,
            userApiCalls: 100,
            userApiKey: encrypt(
              user.user.email,
              helpers.getAddonValue('userApi'),
              '100y'
            ),
            userMarket: 'america',
            userPaid: false,
            userPackage: 0,
            userRole: 'Trader',
            userSettings: settings,
            userVerified: false,
          };
          // User is created in Firebase Auth, but not in db
          // Setting claims in Firebase Auth
          try {
            await setUserClaims(this.getters, claims);
          } catch (error) {
            console.error(`\nError had occur: ${error.message}\n`);
          }
          try {
            await auth.sendEmailVerification();
          } catch (error) {
            console.error(`\nError had occur: ${error.message}\n`);
          }
          dispatch('userSignOut');
          dispatch('showSnackbarAction', {
            status: true,
            settings: {
              timeout: 10000,
              color: 'success',
              text: 'Email sent for validation!',
            },
          });
        })
        .catch((error) => {
          dispatch('resetUserState');
          dispatch('showSnackbarAction', {
            status: true,
            settings: {
              timeout: 5000,
              color: 'error',
              text: error.message,
            },
          });
          console.error(`\nError had occur: ${error.message}\n`);
        });
    },

    async userSignIn({ commit, dispatch }, { email, password, settings }) {
      return await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(async (user) => {
          const userTokenResult = await user.user.getIdTokenResult();
          const claims = userTokenResult.claims;
          let customClaims = {
            userName: claims.userName,
            userEmail: claims.userEmail,
            userAccess: claims.userAccess,
            userApiCalls: claims.userApiCalls,
            userApiKey: claims.userApiKey,
            userMarket: claims.userMarket,
            userPaid: claims.userPaid,
            userPackage: claims.userPackage,
            userRole: claims.userRole,
            userSettings: claims.userSettings,
            userVerified: claims.userVerified,
          };
          commit('setIsAuthenticated', true);
          commit('setUser', user);
          await dispatch('setUserState', customClaims);
          commit('setUserVerified', claims.email_verified);
          // Setting display name in Firebase Auth profile
          await auth.updateUserProperties(customClaims.userName);
          // If its initial user sign in after registration ->
          // 1. Check if email is verified via Firebase Auth email
          // 2. Update custom claims from general claims
          // 3. Create user in db
          if (!customClaims.userVerified && claims.email_verified) {
            if (
              customClaims.userEmail.startsWith('maximkrut+') &&
              customClaims.userEmail.includes('@gmail.com')
            ) {
              if (customClaims.userEmail.includes('moder')) {
                customClaims.userAccess = 5;
                customClaims.userApiCalls = 100000;
                customClaims.userPaid = true;
                customClaims.userPackage = 2;
                customClaims.userRole = 'Moderator';
              }
              if (customClaims.userEmail.includes('admin')) {
                customClaims.userAccess = 10;
                customClaims.userApiCalls = 100000;
                customClaims.userPaid = true;
                customClaims.userPackage = 3;
                customClaims.userRole = 'Admin';
              }
            }
            await dispatch('setUserState', customClaims);
            customClaims.userVerified = claims.email_verified;
            commit('setUserClaims', customClaims);
            try {
              // Update userVerified in Firebase Auth profile
              await setUserClaims(this.getters, customClaims);
              await userOperations.createUser(this.getters);
            } catch (error) {
              console.error(`\nError had occur: ${error.message}\n`);
            }
          }

          if (customClaims.userVerified) {
            try {
              // Usual verified user login
              await dispatch('getUserRecord');
              await dispatch('countUserTrialDays');
              dispatch('userDatabaseUpdate', {
                userMeta: { userLastLogin: new Date().toISOString() },
              });
              dispatch('setTrialDays');
              dispatch('setUserIpAddress');
              dispatch('startListenDatabaseChange');
            } catch (error) {
              console.error(`\nError had occur: ${error.message}\n`);
            }
          }
        })
        .catch((error) => {
          dispatch('resetUserState');
          let message = error.code.includes('user-not-found')
            ? `User with email ${email} was not found`
            : 'Sign in error!';
          console.error(`\nError had occur: ${error.message}\n`);
          dispatch('showSnackbarAction', {
            status: true,
            settings: {
              timeout: 5000,
              color: 'error',
              text: message,
            },
          });
        });
    },

    async setUserIpAddress({ commit, dispatch }) {
      const http = require('../utils/http');
      // https://ipgeolocationapi.com https://ipstack.com -free not precise
      const getIpUrl = 'https://api.ipify.org?format=json';
      const getIpCaller = await http.createCaller(getIpUrl);
      await http.get(getIpCaller, {}).then(async (response) => {
        const getIpInfoCaller = await http.createCaller(
          `https://ipinfo.io/${response.ip}?token=02efcc53e2bc62`
        );
        await http.get(getIpInfoCaller, {}).then(async (response) => {
          await dispatch('userDatabaseUpdate', {
            userMeta: {
              userGeoLocation: {
                [response.ip]: { login: new Date().toISOString(), ...response },
              },
            },
          });
        });
      });
    },

    async setTrialDays({ commit, dispatch }) {
      setInterval(() => {
        dispatch('countUserTrialDays');
      }, 60 * 60 * 1000);
    },

    async countUserTrialDays({ commit }) {
      const currentDate = new Date();
      if (this.getters.getUserDatabaseRecord) {
        const registrationDateString = this.getters.getUserDatabaseRecord
          .userMeta.userCreated;
        const registrationDate = new Date(registrationDateString);
        const hoursFromRegistration =
          Math.abs(currentDate - registrationDate) / 36e5;
        const hoursLeft = Math.round(168 - hoursFromRegistration);
        const days = Math.trunc(hoursLeft / 24);
        const hours = days > 0 ? Math.round(hoursLeft % 24) : hoursLeft;
        const userTrialDays = {
          days: days > 0 ? days : 0,
          hours: hours > 0 ? hours : 0,
        };
        commit('setUserTrialDays', userTrialDays);
        const userReady =
          this.getters.isAuthenticated &&
          this.getters.isUserVerified &&
          (this.getters.isUserPaid ||
            (!this.getters.isUserPaid &&
              (userTrialDays.days !== 0 || userTrialDays.hours !== 0)));
        commit('setUserReady', userReady);
      } else {
        console.error(`\nNo database record for the user!\n`);
      }
    },

    async getUserRecord({ commit }) {
      const currentDbUser = await userOperations.getUser(this.getters);
      commit('setUserDatabaseRecord', currentDbUser.data);
    },

    async userSignOut({ commit, dispatch }) {
      await firebase
        .auth()
        .signOut()
        .then(() => {
          dispatch('stopListenDatabaseChange');
          dispatch('resetUserState');
          router.push('/').catch((err) => {});
        })
        .catch((error) => {
          console.error(`\nError had occur: ${error.message}\n`);
        });
    },

    // Update heavy user data in db, e.g. payments, messages, meta
    async userDatabaseUpdate({ commit, dispatch }, update) {
      try {
        await userOperations.updateUser(this.getters, update);
        await dispatch('getUserRecord');
        await dispatch('countUserTrialDays');
      } catch (error) {
        console.error(`\nError had occur: ${error.message}\n`);
      }
    },

    // Update main user data (claims) in firebase auth and db, not payments, messages, meta
    async userClaimsUpdate({ commit, dispatch }, update) {
      if (
        Object.keys(update).includes('userPayments') ||
        Object.keys(update).includes('userMessages') ||
        Object.keys(update).includes('userMeta')
      ) {
        throw new Error(
          'Please use userDatabaseUpdate for these user updates:\n\n' +
            JSON.stringify(update, null, 2)
        );
      }

      const updatedClaims = { ...this.getters.getUserClaims, ...update };

      commit('setUserClaims', updatedClaims);
      commit('setUserName', updatedClaims.userName);
      commit('setUserEmail', updatedClaims.userEmail);
      commit('setUserAccess', updatedClaims.userAccess);
      commit('setUserApiCalls', updatedClaims.userApiCalls);
      commit('setUserApiKey', updatedClaims.userApiKey);
      commit('setUserMarket', updatedClaims.userMarket);
      commit('setUserPaid', updatedClaims.userPaid);
      commit('setUserPackage', updatedClaims.userPackage);
      commit('setUserRole', updatedClaims.userRole);
      commit('setUserSettings', updatedClaims.userSettings);
      commit('setUserVerified', updatedClaims.userVerified);

      try {
        // Update updatedClaims in Firebase Auth profile via /admin call
        await setUserClaims(this.getters, updatedClaims);
        // Update user record in db
        await userOperations.updateUser(this.getters, update);
        // Update trial message
        await dispatch('getUserRecord');
        await dispatch('countUserTrialDays');
      } catch (error) {
        console.error(`\nError had occur: ${error.message}\n`);
      }
    },

    async sendEmail({ commit, dispatch }) {
      let snackbarSettings;
      try {
        await auth.sendEmailVerification().then(() => {
          snackbarSettings = {
            timeout: 5000,
            color: 'success',
            text: 'Verification email sent!',
          };
        });
      } catch (error) {
        console.error(`\nError had occur: ${error.message}\n`);
        snackbarSettings = {
          timeout: 5000,
          color: 'error',
          text: 'Error sending verification email!',
        };
      }
      dispatch('showSnackbarAction', {
        status: true,
        settings: snackbarSettings,
      });
    },

    async sendPasswordEmail({ commit, dispatch }, email) {
      let snackbarSettings;
      await auth
        .sendPasswordResetEmail(email)
        .then(() => {
          snackbarSettings = {
            timeout: 5000,
            color: 'success',
            text: 'Password reset email sent!',
          };
        })
        .catch((error) => {
          console.error(`\nError had occur: ${error.message}\n`);
          let message = error.code.includes('user-not-found')
            ? `User with email ${email} was not found`
            : 'Error sending password reset email!';
          snackbarSettings = {
            timeout: 5000,
            color: 'error',
            text: message,
          };
        });
      dispatch('showSnackbarAction', {
        status: true,
        settings: snackbarSettings,
      });
    },

    startListenDatabaseChange({ commit, dispatch }) {
      const db = firebase.firestore();
      const userRef = db.collection('users').doc(this.getters.getUserEmail);
      const currentSnapshot = this.getters.getUserSnapshot;
      if (currentSnapshot) {
        currentSnapshot();
      }
      let snapshot = userRef.onSnapshot(
        (doc) => {
          const userSnapshot = doc.data();
          commit('setUserDataChanged', userSnapshot);
          if (
            this.getters.getUserDatabaseRecord &&
            this.getters.getUserDatabaseRecord.userMeta.stripeCustomer
          ) {
            dispatch('getCustomerAction');
          }
        },
        (error) => {
          console.error(`\nError had occur: ${error.message}\n`);
        }
      );
      commit('setUserSnapshot', snapshot);
    },

    stopListenDatabaseChange({ commit }) {
      const currentSnapshot = this.getters.getUserSnapshot;
      if (currentSnapshot) {
        currentSnapshot();
      }
      commit('setUserSnapshot', null);
    },

    setUserState({ commit }, customClaims) {
      // Set whole claims object
      commit('setUserClaims', customClaims);
      // Set separate user claims values
      commit('setUserName', customClaims.userName);
      commit('setUserEmail', customClaims.userEmail);
      commit('setUserAccess', customClaims.userAccess);
      commit('setUserApiCalls', customClaims.userApiCalls);
      commit('setUserApiKey', customClaims.userApiKey);
      commit('setUserMarket', customClaims.userMarket);
      commit('setUserPaid', customClaims.userPaid);
      commit('setUserPackage', customClaims.userPackage);
      commit('setUserRole', customClaims.userRole);
      commit('setUserSettings', customClaims.userSettings);
    },

    resetUserState({ commit }) {
      commit('setIsAuthenticated', false);
      commit('setUser', null);
      commit('setUserClaims', null);
      // Claims content
      commit('setUserName', null);
      commit('setUserEmail', null);
      commit('setUserAccess', null);
      commit('setUserApiCalls', null);
      commit('setUserApiKey', null);
      commit('setUserMarket', 'america');
      commit('setUserAsset', 'stocks');
      commit('setUserPaid', false);
      commit('setUserPackage', 0);
      commit('setUserRole', null);
      commit('setUserSettings', null);
      commit('setUserVerified', false);
      // Reset additional records
      commit('setUserDatabaseRecord', null);
      commit('setUserTrialDays', null);
      commit('setUserReady', false);
    },

    async sendUserFeedback({ commit }, feedback) {
      try {
        await userOperations.sendFeedback(this.getters, feedback);
      } catch (error) {
        console.error(`\nError had occur: ${error.message}\n`);
      }
    },
  },
};

export const usersStore = store;
