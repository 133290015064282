import * as assetsOperations from '../utils/operations/assets';
import * as assetsMapping from '../utils/mapper';

const store = {
  state: {
    date: null, // Operational date, can be demo -1,2,3 days
    assetTypes: ['stocks', 'crypto', 'currency', 'metals', 'funds'],
    assetsNames: null,
    assetsData: null,
    assetsDataCalls: {
      names: false,
      crypto: false,
      currency: false,
      metals: false,
      funds: false,
    },
  },
  mutations: {
    setDate(state, payload) {
      state.date = payload;
    },
    setAssetTypes(state, payload) {
      state.assetTypes = payload;
    },
    setAssetsNames(state, payload) {
      state.assetsNames = payload;
    },
    setAssetsData(state, payload) {
      state.assetsData = payload;
    },
    setAssetsDataCalls(state, payload) {
      state.assetsDataCalls = payload;
    },
  },
  getters: {
    getDate: (state) => {
      return state.date;
    },
    getAssetTypes: (state) => {
      return state.assetTypes;
    },
    getAssetsNames: (state) => {
      return state.assetsNames;
    },
    getAssetsData: (state) => {
      return state.assetsData;
    },
    getAssetsDataCalls: (state) => {
      return state.assetsDataCalls;
    },
  },
  actions: {
    async fetchAssetsNames({ commit }) {
      let assetsDataCalls = this.getters.getAssetsDataCalls;
      if (assetsDataCalls['names']) {
        return;
      }
      try {
        const assetTypesToCall = this.getters.getAssetTypes.slice(1, 5);

        let assetsNames = this.getters.getAssetsNames || {};

        assetsDataCalls['names'] = true;
        for await (let [index, assetType] of assetTypesToCall.entries()) {
          if (assetType.includes('stocks')) continue;
          const assetResponse = await assetsOperations.getAssets(
            this.getters,
            '/' + assetType
          );
          const assetRecord = {};
          assetRecord[assetType] = assetResponse.data;
          assetsNames = { ...assetsNames, ...assetRecord };
        }
        assetsDataCalls['names'] = false;
        commit('setAssetsNames', assetsNames);
      } catch (error) {
        assetsDataCalls['names'] = false;
        if (!error.message.includes('No assets found')) {
          console.error(`\nError had occur: ${error.message}\n`);
        }
      }
    },

    async fetchAssetsData({ commit }) {
      const assetTypesToCall = this.getters.getAssetTypes.slice(1, 5);
      let assetsDataCalls = this.getters.getAssetsDataCalls;
      try {
        let assetsData = this.getters.getAssetsData || {};
        assetTypesToCall.forEach((assetType) => {
          const startTime = new Date();
          // Check if calls already in process
          if (assetsDataCalls[assetType]) {
            return;
          }
          assetsDataCalls[assetType] = true;
          commit('setAssetsDataCalls', assetsDataCalls);
          this.dispatch('fetchAssetDataType', {
            assetType,
          })
            .then((assetTypeData) => {
              const assetDataRecord = {};
              assetDataRecord[assetType] = assetTypeData;
              assetsData = { ...assetsData, ...assetDataRecord };
              commit('setAssetsData', assetsData);
              const endTime = new Date();
              let timeDiff = endTime - startTime;
              timeDiff /= 1000;
              let seconds = Math.round(timeDiff);
              // console.log(
              //   `\n\nAsset data for --${assetType}-- received in ${seconds} seconds!"\n\n`
              // );
              assetsDataCalls[assetType] = false;
              commit('setAssetsDataCalls', assetsDataCalls);
            })
            .catch((error) => {
              assetsDataCalls[assetType] = false;
              commit('setAssetsDataCalls', assetsDataCalls);
              // console.log(
              //   `\n\nAsset data for --${assetType}-- was not received!"\n\nError: ${JSON.stringify(
              //     error,
              //     null,
              //     2
              //   )}`
              // );
            });
        });
      } catch (error) {
        if (!error.message.includes('No asset data found')) {
          console.error(`\nError had occur: ${error.message}\n`);
        }
      }
    },

    async fetchAssetDataType({ commit }, { assetType }) {
      // Convert local time to NY date/time
      const newYorkTimeString = this.getters.getLocalTime.toLocaleString(
        'en-US',
        {
          timeZone: 'America/New_York',
        }
      );

      let date = new Date(newYorkTimeString);

      const weekday = date.getDay();
      let minusDays, minusDemoDays;
      switch (weekday) {
        case 6:
          minusDays = 1;
          minusDemoDays = 1;
          break;
        case 0:
          minusDays = 2;
          minusDemoDays = 2;
          break;
        case 1:
          minusDays = 0;
          minusDemoDays = 3;
          break;
        default:
          minusDays = 0;
          minusDemoDays = 1;
          break;
      }

      const demoDate =
        !this.getters.isAuthenticated ||
        !this.getters.isUserVerified ||
        !this.getters.isUserReady
          ? date.setDate(date.getDate() - minusDemoDays)
          : date.setDate(date.getDate() - minusDays);

      let queryDate = new Date(demoDate);
      let yyyy = queryDate.getFullYear();
      let mm = queryDate.getMonth() + 1;
      let dd = queryDate.getDate();
      if (mm < 10) {
        mm = `0${mm}`;
      }
      if (dd < 10) {
        dd = `0${dd}`;
      }
      let isoDate = yyyy + '-' + mm + '-' + dd;

      // TODO Refactor, as same change committing in tickers.js
      commit('setDate', isoDate); // Backend call date considering demo and paid user calls

      const currentAssetTypeNames = this.getters.getAssetsNames[assetType];

      try {
        let assetData = {};

        for await (let [index, assetName] of currentAssetTypeNames.entries()) {
          // if (index > 1) continue; // TEST MODE
          const uri = `/data/${assetType}?name=${assetName.id}&date=${isoDate}`;
          const assetDataResponse = await assetsOperations.getAssetData(
            this.getters,
            uri
          );
          const assetDataRecord = {};
          assetDataRecord[assetName.id] = assetDataResponse;
          assetData = { ...assetData, ...assetDataRecord };
        }

        Object.keys(assetData).forEach((assetName) => {
          const assetObject = assetData[assetName];
          if (
            assetObject.mood.length == 0 ||
            (assetObject.mood.length != 0 &&
              assetObject.mood[0].data.coefficient == 0) ||
            assetObject.mood.length == 0 ||
            (assetObject.mood.length != 0 &&
              assetObject.mood[0].data.magnitude == 0) ||
            assetObject.price.length == 0
          ) {
            delete assetData[assetName];
          }
        });

        const assetDataMapped = await assetsMapping.getAssetDataMapped(
          assetData
        );

        return assetDataMapped;
      } catch (error) {
        if (!error.message.includes('No asset data found')) {
          console.error(`\nError had occur: ${error.message}\n`);
        }
        return null;
      }
    },
  },
};

export const assetsStore = store;
